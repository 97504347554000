import { FC, useCallback, useState } from 'react';
import { GetVoiceUrl, IconLocation } from '../../../../utils/URLUtils';
import styles from './AudioPlayButton.module.scss';

interface AudioPlayButtonProps {
    wordId: number;
    accent?: string;
    onPlayStart?: () => void;
    onPlayEnd?: () => void;
}
const AudioPlayButton: FC<AudioPlayButtonProps> = ({
    wordId,
    accent = 'gb',
    onPlayStart,
    onPlayEnd
}) => {
    const [male, setMale] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleClick = useCallback(() => {
        const link = GetVoiceUrl(accent, male ? 'm' : 'f', wordId + '.mp3');
        const audio = new Audio(link);

        setIsPlaying(true);
        onPlayStart?.(); // Call onPlayStart callback

        audio.play();
        audio.onended = () => {
            setIsPlaying(false);
            onPlayEnd?.(); // Call onPlayEnd callback
        };

        setMale(prev => !prev);
    }, [accent, male, wordId, onPlayStart, onPlayEnd]);

    return (
        <img
            src={IconLocation('play2')}
            alt='play audio'
            className={`${styles.playButton} ${isPlaying ? styles.playing : ''}`}
            onClick={handleClick}
        />
    );
};
export default AudioPlayButton;