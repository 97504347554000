export const SliderSettings = () => ({
    centerMode: true,
    lazyLoad: 'ondemand' as 'ondemand',
    centerPadding: "30px",
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    initialSlide: 0,
    accessibility: false,

});
