import { useLearning } from '../../../../hook/useLearning';
import { Period } from '../../../../model/enum/enums';
import { color } from '../../../../utils/ColorUtils';
import CircleChart from '../../../ui/CircleChart/CircleChart';
import styles from './Charts.module.scss';

const Charts: React.FC<{ period: Period }> = ({ period }) => {
    const { learningInfo } = useLearning();
    const MyProgress = learningInfo?.MyAccount?.MyProgress;


    return (
        <div className={styles.charts}>
            {MyProgress?.[period].Items.map((item, index) => (
                <CircleChart
                    key={index}
                    value={item.Percentage}
                    color={color(item.Percentage)}
                    size={period === Period.Daily ? 45 : 70}
                    ringWidth={5}
                    title={item.Subtitle ? `${item.Title} \n ${item.Subtitle}` : item.Title}
                />
            ))}
        </div>
    );
};


export default Charts;