import { FC } from 'react';
import Modal from 'src/components/ui/Modal/Modal';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './WrongAnswerModal.module.scss';
interface WrongAnswerModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

const WrongAnswerModal: FC<WrongAnswerModalProps> = ({ isModalOpen, setIsModalOpen }) => {

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <div className={styles.wrongAnswerModal}>
                <div className={styles.header}>
                    <h2>Try again</h2>
                    <img src={IconLocation('x')} className={styles.close}
                        alt='close' onClick={() => setIsModalOpen(false)} />
                </div>
                <p>you didn't get it right.</p>
                <p>It's OK. Try again</p>
                <hr />
                <button onClick={() => setIsModalOpen(false)}
                    className={styles.okButton}>Ok</button>
            </div>
        </Modal>
    );
};

export default WrongAnswerModal;