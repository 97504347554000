
import { useMemo } from 'react';
import Lottie from 'react-lottie-player';
import { getRandomPraise } from 'src/utils/PraiseUtils';
import { GetReviewLottieUrl } from 'src/utils/URLUtils';
import styles from './LessonLottie.module.scss';

interface Props {
    lottieType?: string;
    showPraise?: boolean;
}

const LessonLottie = ({ lottieType = 'check-mark', showPraise = true }: Props) => {
    const lottieUrl = useMemo(() => GetReviewLottieUrl(lottieType), [lottieType]);
    const praise = useMemo(() => getRandomPraise(), []);

    return (
        <div className={styles.lottieContainer}>
            {showPraise && <h1 className={styles.praise}>{praise}</h1>}
            <Lottie
                path={lottieUrl}
                loop
                play
                className={styles.lottie}
            />
        </div>
    );
};

export default LessonLottie;
