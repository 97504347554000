import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCountries } from 'src/hook/useCountries';
import { useNote } from 'src/hook/useNote';
import { useServiceWorker } from 'src/hook/useServiceWorker';
import { useSocial } from 'src/hook/useSocial';
import { useWordList } from 'src/hook/useWordList';
import { GetStartPageUrl } from 'src/utils/URLUtils';
import useUser from '../../hook/useUser';
import { useWords } from '../../hook/useWords';
import { useWordTranslate } from '../../hook/useWordTranslate';
import ErrorMessage from '../ui/Error/Error';
import Spinner from '../ui/Spinner/Spinner';
import { RoutesPage } from './RoutesPage';

const Load: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { fetchUserData, isAuthenticated, isUserLoaded } = useUser();
    const location = useLocation();
    const token = useMemo
        (() => new URLSearchParams(location.search).get('t')
            , [location.search]);

    const { fetchWords } = useWords(false);
    const { fetchCountries } = useCountries(false);
    const { fetchTranslates } = useWordTranslate(undefined, false);
    const { registerWorker } = useServiceWorker();
    const { fetchNoteData } = useNote(false);
    const { fetchSocialInfo } = useSocial();
    const { fetchLearningWithWordList } = useWordList();


    const initializeApp = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            const user = await fetchUserData(token!);
            await Promise.all([
                fetchLearningWithWordList(token!),
                fetchSocialInfo(user?.UserId!, user?.CountryCode),
                fetchNoteData(user?.UserId),
                fetchWords(false),
                fetchCountries(false),
                fetchTranslates(user?.NativeLanguageCode, false),
                registerWorker()
            ]);



            // const home = datUser?.LanguageLevel === LanguageLevel.Basic ? 'lessons' : 'wordlists';
            // navigate(`/${home}`);

        } catch (error) {
            console.log('error', error);
            //   window.location.href = GetStartPageUrl();
            setError(error as string);
        } finally {
            setIsLoading(false);
        }
    }, [registerWorker, token,
        fetchWords, fetchCountries, fetchTranslates,
        fetchNoteData, fetchSocialInfo,
        fetchLearningWithWordList, fetchUserData, isLoading]);


    useEffect(() => {
        if (token) {
            initializeApp();
        }
    }, []);


    useEffect(() => {
        if (!token && isUserLoaded && !isAuthenticated()) {
            window.location.href = GetStartPageUrl();
        }
    }, [token, isUserLoaded, isAuthenticated]);


    if (isLoading || !isAuthenticated()) return <Spinner fullPage={true} />;
    else if (error) return <ErrorMessage message={error} />;
    else return <RoutesPage />;
};

export default memo(Load);