import React, { useEffect, useState } from 'react';
import { useNote } from 'src/hook/useNote';
import { Word } from 'src/model/Word';
import Modal from '../../Modal/Modal';
import styles from './NoteModal.module.scss';


interface NoteModalProps {
    isOpen: boolean;
    onClose: () => void;
    word: Word;
    senseId: string;
}

export const NoteModal: React.FC<NoteModalProps> = ({ isOpen, onClose, word, senseId }) => {
    const { getNotesBySense, saveNotes } = useNote();
    const [textValue, setTextValue] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (isLoaded) return;
        const notes = getNotesBySense(word.ID.toString(), senseId);
        if (notes) {
            setTextValue(notes.join('\n'));
        }
        else {
            setTextValue('');
        }
        setIsLoaded(true);
    }, [word.ID, getNotesBySense, senseId, isLoaded]);

    const save = () => {
        saveNotes(word.ID.toString(), senseId, textValue);
        onClose();
    };

    if (!word) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className={styles.modalContent}>
                <h2>Add a note</h2>
                <textarea
                    className={styles.text}
                    value={textValue}
                    onChange={(e) => setTextValue(e.target.value)}
                    placeholder='Enter your notes here...'
                />
                <button onClick={save}>Save</button>
            </div>
        </Modal>
    );
};