import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Note } from 'src/model/Note';
import { noteStorage } from 'src/utils/StorageUtils';

interface NoteState {
    data: Note | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: NoteState = {
    data: null,
    isLoading: false,
    error: null
};

const noteSlice = createSlice({
    name: 'note',
    initialState,
    reducers: {
        getNote: (state) => {
            const data = noteStorage.get();
            state.data = data;
        },
        setNote: (state, action: PayloadAction<Note | null>) => {
            noteStorage.set(action.payload);
            state.data = action.payload;
        },
        updateNote: (state, action: PayloadAction<Partial<Note>>) => {
            state.data = noteStorage.update(action.payload);
        },
        logout: (state) => {
            noteStorage.set(null);
            state.data = null;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },

    }
});

export const noteActions = noteSlice.actions;
export default noteSlice.reducer;
