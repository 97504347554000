import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../model/enum/enums";
import { Social } from "../../model/Social";
import { socialStorage } from "../../utils/StorageUtils";
import { GetFriendsUrl, GetNearbyPeopleUrl } from "../../utils/URLUtils";

interface SocialState {
    socialInfo: Social | null;
    status: LoadingStatus;
    error: string | null;
}

const initialState: SocialState = {
    socialInfo: null,
    status: LoadingStatus.IDLE,
    error: null
};

export const fetchSocialData = createAsyncThunk(
    'social/fetchData',
    async ({ userId, countryCode }: { userId: string, countryCode: string }, { rejectWithValue }) => {
        try {
            const [friendsResponse, peopleNearByResponse] = await Promise.all([
                fetch(GetFriendsUrl(userId)),
                fetch(GetNearbyPeopleUrl(userId, countryCode))
            ]);

            if (!friendsResponse.ok || !peopleNearByResponse.ok) {
                throw new Error('Failed to fetch social data');
            }

            const [friends, peopleNearBy] = await Promise.all([
                friendsResponse.json(),
                peopleNearByResponse.json()
            ]);

            const socialData: Social = {
                Friends: friends,
                PeopleNearBy: peopleNearBy
            };

            return socialData;
        } catch (error) {
            return rejectWithValue((error as Error).message);
        }
    }
);

const socialSlice = createSlice({
    name: "social",
    initialState,
    reducers: {
        getSocial: (state, action: PayloadAction<{}>) => {
            const data = socialStorage.get();
            state.socialInfo = data;
        },
        setSocial: (state, action: PayloadAction<Social | null>) => {
            socialStorage.set(action.payload);
            state.socialInfo = action.payload;
        },
        updateSocial: (state, action: PayloadAction<Partial<Social>>) => {
            state.socialInfo = socialStorage.update(action.payload);
        },
        logout: (state) => {
            socialStorage.set(null);
            state.socialInfo = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSocialData.pending, (state) => {
                state.status = LoadingStatus.LOADING;
            })
            .addCase(fetchSocialData.fulfilled, (state, action) => {
                state.status = LoadingStatus.SUCCEEDED;
                state.socialInfo = action.payload;
                socialStorage.set(action.payload);
                state.error = null;
            })
            .addCase(fetchSocialData.rejected, (state, action) => {
                state.status = LoadingStatus.FAILED;
                state.error = action.error.message ?? 'An unknown error occurred';
            });
    }
});

export const socialActions = { ...socialSlice.actions };
export default socialSlice.reducer;