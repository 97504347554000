import { useCallback } from 'react';
import { useLearning } from '../../../../hook/useLearning';
import { Direction } from '../../../../model/enum/enums';
import { Word, WordType } from '../../../../model/Word';
import { Chevron } from '../../../ui/Chevron/Chevron';
import styles from './KnowledgeMap.module.scss';


interface KnowledgeMapProps {
    currentRangeNumber?: number;
    currentWord?: Word;
    wordType: WordType;
    words: Word[];
    onRangeChange: (newRange: number) => void;
}

const KnowledgeMap: React.FC<KnowledgeMapProps> = ({
    currentRangeNumber = 1,
    currentWord,
    words,
    wordType = WordType.Word,
    onRangeChange
}) => {
    const { learningInfo } = useLearning();

    const calculateRange = (rangeNum: number): [number, number] => {
        const start = (rangeNum - 1) * 100 + 1;
        const end = rangeNum * 100;
        return [start, end];
    };

    const [rangeStart, rangeEnd] = calculateRange(currentRangeNumber);

    const getSquareClassName = (wordId: number): string => {
        let className = styles.square;
        //   let wordId = getWordByRank(range, wordType)?.ID;
        //  let wordId = words[range]?.ID;
        // Start with the base class
        if (currentWord?.ID === wordId) {
            className += ` ${styles.current}`; // Add current class if applicable
        }
        if (learningInfo?.KnownWordIds.includes(wordId)) {
            className += ` ${styles.known}`; // Add known class if applicable
        }
        if (learningInfo?.UnknownWordIds.includes(wordId)) {
            className += ` ${styles.shouldLearn}`; // Add shouldLearn class if applicable
        }

        return className; // Return all applicable classes
    };

    const handleRangeChange = useCallback((direction: Direction) => {
        const newRangeNumber = direction === Direction.Next
            ? currentRangeNumber + 1
            : currentRangeNumber - 1;
        onRangeChange(newRangeNumber);
    }, [onRangeChange, currentRangeNumber]);

    return (
        <section className={styles.knowledgeMap}>
            <div className={styles.rangeText}>
                Range {rangeStart} - {rangeEnd}
            </div>
            <div className={styles.container}>

                <Chevron type="left" size={24}
                    onClick={() => handleRangeChange(Direction.Prev)} />

                <div className={styles.gridWrapper}>
                    <div className={styles.grid}>
                        {words.map((word, index) => (
                            <div
                                key={index}
                                className={getSquareClassName(word?.ID)}
                            />
                        ))}
                    </div>
                </div>


                <Chevron type="right" size={24}
                    onClick={() => handleRangeChange(Direction.Next)} />
            </div>
        </section>
    );
};

export default KnowledgeMap;