import { AnimationType, LearnWizardStep } from '../../../../model/enum/enums';
import styles from './FooterSection.module.scss';

interface WizardFooterProps {
    onPrevious: (animation: AnimationType) => void;
    onNext: (animation: AnimationType) => void;
    isFirstStep: boolean;
    isLastStep: boolean;
    stepKey: LearnWizardStep;
}

const WizardFooter = ({ onPrevious, onNext, isFirstStep, isLastStep, stepKey }: WizardFooterProps) => (
    <div className={styles.footerSection}>
        {stepKey === LearnWizardStep.First && (
            <button
                onClick={() => onNext(AnimationType.RotateIn)}
            >
                Continue
            </button>
        )}
        {stepKey === LearnWizardStep.Second && (
            <div className={styles.buttonContainer}>
                <button className={styles.backButton} onClick={() => onPrevious(AnimationType.RotateIn)}>Back</button>
                <button className={styles.continueButton} onClick={() => onNext(AnimationType.RotateIn)}>Continue</button>
            </div>

        )}
        {stepKey === LearnWizardStep.Third && (
            <div className={styles.buttonContainer}>
                <button className={styles.backButton} onClick={() => onPrevious(AnimationType.RotateIn)}>Back</button>
                <button className={styles.continueButton} onClick={() => onNext(AnimationType.RotateIn)}>Done</button>
            </div>

        )}

    </div>
);

export default WizardFooter;