
const BaseRazorUrl = "https://my.wrdp.app";
const BaseCDNLocation = "https://cdn-wordup.com";
const BaseDefinitionUrl = "https://define.wrdp.app";
const BaseSenseImageUrl = "https://word-images.cdn-wordup.com";
const FriendsBaseUrl = "https://friends.wrdp.app";
const BaseSpeechUrl = "https://get-speech.wrdp.app";
const BaseVoiceUrl = "https://speech.cdn-wordup.com";
const BaseSQS = 'https://sqs.eu-west-1.amazonaws.com/077176806650';
const BaseAuthorUrl = "https://author.cdn-wordup.com";
const BaseWisdomUrl = " https://images.pexels.com";
const BaseWebSiteUrl = "https://www.wordupapp.co";
const ChromeExtensionUrl = "https://chromewebstore.google.com/detail/wordup/hjglipkneddkikocdjfhmnachfjoccjn?pli=1";
const BaseTranslateUrl = "https://rncuhmjn7yyscform7jjuqinr40zjhxh.lambda-url.eu-west-1.on.aws";
const BaseNoteUrl = "https://s3-eu-west-1.amazonaws.com/user-translations";

const IsDark = () => false;

export const ImageLocation = (fileName: string) => `${BaseCDNLocation}/AppImages/${fileName}`;

export const IconLocation = (fileName: string, forceLight: boolean = false) => `${BaseCDNLocation}/AppImages/Icons/${(IsDark() || forceLight ? 'white' : 'black')}/${fileName}.png`;

export const DirectIconLocation = (fileName: string) => `${BaseCDNLocation}/AppImages/Icons/${fileName}.png `;

export const ScenariosLocation = (fileName: string) => `${BaseCDNLocation}/scenarios/${fileName}.webp `;

export const GetDefinitionResourceUrl = (fileName: string) => `${BaseDefinitionUrl}/Resources/${fileName}`;

export const SenseImageLocation = (senseId: string) => `${BaseSenseImageUrl}/senses/${senseId}.webp`;

export const SenseVideoLocation = (senseId: string) => `${BaseSenseImageUrl}/video/${senseId}.mp4`;

export const GetRazorSiteUrl = (url: string, token?: string, queryString: string = '') => `${BaseRazorUrl}/${url}?t=${token}${queryString ? `&${queryString}` : ''}`;

export const GetWordApiUrl = (wordId: number) => `${BaseDefinitionUrl}/WordById/${wordId}`;

export const GetFriendsUrl = (userId: string) => `${FriendsBaseUrl}/v2/friends/${userId}`;

export const GetNearbyPeopleUrl = (userId: string, country: string) => `${FriendsBaseUrl}/nearby/${userId}/${country}`;

export const GetWordDataUrl = (wordId: number) => `${BaseDefinitionUrl}/ById/${wordId}`;

export const GetTipsImageUrl = (hashExample: string) => `${BaseSenseImageUrl}/tips/${hashExample}.webp`;

export const GetSpeechUrl = (accent: string = 'gb', text: string) => `${BaseSpeechUrl}/url/q/${accent}/${text}`;

export const GetVoiceUrl = (accent: string = 'gb', sex: string, wordId: string) => `${BaseVoiceUrl}/words/all/${accent}/${sex}/${wordId}`;

export const GetQueueUrl = (queueName: string) => `${BaseSQS}/${queueName}`;

export const GetWellDoneLottieUrl = () => `${BaseCDNLocation}/animation/Review/Welldone/${Math.floor(Math.random() * 15) + 1}.json`;

export const GetStartPageUrl = () => `${BaseRazorUrl}/onboarding/start`;

export const GetTranslateFilesUrl = (userLanguage: string) => `${BaseCDNLocation}/translations/v6/${userLanguage}.json.gz`;

export const GetResourcesUrl = (fileName: string) => `${BaseCDNLocation}/Resources/${fileName}`;

export const GetReviewLottieUrl = (fileName: string) => `${BaseCDNLocation}/animation/Review/${fileName}.json`;

export const GetSoundUrl = (fileName: string) => `${BaseCDNLocation}/audio/sounds/${fileName}.mp3`;

export const GetWordDataFileUrl = (wordId: number) => `${BaseCDNLocation}/v2024-1-18/${wordId}.gz`;

export const GetAuthorImageUrl = (fileName: string) => `${BaseAuthorUrl}/img-webp/${fileName}.webp`;

export const GetWisdomImageUrl = () => `${BaseCDNLocation}/images/wisdom/${Math.floor(Math.random() * 110) + 1}.jpg`;

export const GetWebSiteDictionaryUrl = (word: string) => `${BaseWebSiteUrl}/dictionary/${word}`;

export const GetWebSiteUrl = () => BaseWebSiteUrl;

export const GetLessonsVideoUrl = (hashPhrase: string) => `${BaseCDNLocation}/scenarios/video/${hashPhrase}.mp4`;

//export const GetTranslateApiUrl = (phrase?: string, token?: string) => `${BaseRazorUrl}/translation/direct/${encodeURIComponent(phrase ?? '')}?t=${token}`;

export const GetPeopleImageUrl = (fileName: string) => `${BaseCDNLocation}/scenarios/people/${fileName?.toLowerCase()}.webp `;

export const GetottieUrl = (fileName: string) => `${BaseCDNLocation}/animation/${fileName}.json`;

export const GetSpecificVoiceUrl = (voice: string, text: string) => `${BaseSpeechUrl}/by-voice/${voice}/${encodeURIComponent(text)}`;

export const GetChromeExtensionUrl = () => ChromeExtensionUrl;

export const GetTranslateApiUrl = () => BaseTranslateUrl;

export const GetNoteUrl = (userId?: string) => `${BaseNoteUrl}/${userId}-Notes.gz?v=${crypto.randomUUID()}`;
