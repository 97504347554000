import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chevron } from 'src/components/ui/Chevron/Chevron';
import styles from './LessonHeader.module.scss';

interface LessonHeaderProps {
    lessonName?: string;
}

export const LessonHeader: FC<LessonHeaderProps> = ({ lessonName }) => {
    const navigate = useNavigate();

    const back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/lessons');
        }
    }

    return (
        <header className={styles.lessonHeader}>
            <Chevron type="left" size={34}
                onClick={back} />
            <h1 className={styles.title}>{lessonName}</h1>
        </header>
    );
};
