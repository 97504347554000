import { FC } from 'react';
import { IconLocation } from '../../../utils/URLUtils';
import styles from './Chevron.module.scss';

interface ChevronProps {
    type: string;
    size?: number;
    onClick?: () => void;
}

export const Chevron: FC<ChevronProps> = ({ type, size = 24, onClick }) => {
    return (
        <img
            src={IconLocation(`chevron-${type}`)}
            alt="chevron"
            width={size}
            height={size}
            className={styles.chevron}
            onClick={onClick}
        />
    );
};
