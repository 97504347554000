export const isRTL = (text?: string | null): boolean => {
    if (!text) return false;
    // Check if the text contains RTL characters (Arabic, Hebrew, etc.)
    const rtlRegex = /[\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/;
    return rtlRegex.test(text);
};

export const getDirection = (text?: string | null): string => {
    if (!text) return 'ltr';
    return isRTL(text) ? 'rtl' : 'ltr';
};
