import { WordList } from 'src/model/WordList';
import { MenuPosition, WordListType } from '../../../../../model/enum/enums';
import { IconLocation } from '../../../../../utils/URLUtils';
import DropdownMenu from '../../../../ui/DropDownMenu/DropdownMenu';
import styles from './Menu.module.scss';

interface MenuProps {
    isOpen: boolean;
    onClose: () => void;
    trigger: HTMLSpanElement | null;
    wordList: WordList;
}

const Menu = ({ isOpen, onClose, trigger, wordList }: MenuProps) => {

    return (
        <DropdownMenu
            isOpen={isOpen}
            onClose={onClose}
            trigger={trigger}
            position={MenuPosition.BottomLeft}
        >
            <div className={styles.menuItems}>
                {wordList.Type === WordListType.Custom &&
                    <>
                        <div className={styles.menuItem}>
                            <img src={IconLocation('plus2')} alt="Plus" />
                            <span>Add Words</span>
                        </div>
                        <div className={styles.menuItem}>
                            <img src={IconLocation('download')} alt="Download" />
                            <span>Import Words</span>
                        </div>
                        <div className={styles.menuItem}>
                            <img src={IconLocation('x')} alt="X" />
                            <span>Remove Words</span>
                        </div>
                        <div className={styles.menuItem}>
                            <img src={IconLocation('clipboard')} alt="ClipBoard" />
                            <span>Edit List</span>
                        </div>
                    </>
                }
                <div className={styles.menuItem}>
                    <img src={IconLocation('share2')} alt="Share" />
                    <span>Share List</span>
                </div>
                <div className={styles.menuItem}>
                    <img src={IconLocation('sort')} alt="Sort" />
                    <span>Sort</span>
                </div>
            </div>
        </DropdownMenu>
    );
};

export default Menu;