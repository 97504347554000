import { useCallback, useEffect, useState } from 'react';
import { useLearnWizard } from 'src/hook/useLearnWizard';
import { useWordData } from 'src/hook/useWordData';
import { QueryString } from 'src/utils/QueryStringUtils';
import { useLearning } from '../../../../../../hook/useLearning';
import useUser from '../../../../../../hook/useUser';
import { Actions, LearningArray, LearnWizardStep } from '../../../../../../model/enum/enums';
import { Word } from '../../../../../../model/Word';
import styles from './ButtonSection.module.scss';

type ButtonSectionProps = {
    word: Word;
    moveNext: () => void;
}

export const ButtonSection = ({ word, moveNext }: ButtonSectionProps) => {
    const { addWord, removeWord } = useLearning();
    const { saveProgress } = useUser();
    const { learningInfo } = useLearning();
    const [existWord, setExistWord] = useState<string>("");
    const { open } = useLearnWizard();
    const { wordData } = useWordData(word);
    const { userInfo, updateUserInfoField } = useUser();


    const checkRange = useCallback(() => {
        const wordListId = QueryString('id');
        if (!wordListId || wordListId !== "-1") return;
        if (!userInfo?.SelectedRange) return;

        const wordRange = word?.Rank <= 100 ? 1 : Math.floor((word?.Rank || 0) / 100) + 1;
        if (wordRange !== userInfo?.SelectedRange)
            updateUserInfoField('SelectedRange', wordRange);

    }, [word, userInfo?.SelectedRange, updateUserInfoField]);

    const handleShouldLearn = useCallback(() => {
        checkRange();
        addWord(LearningArray.UnknownWordIds, word.ID);
        addWord(LearningArray.ToLearn, word.ID);
        saveProgress(Actions.Learn, word.ID.toString());

        if (learningInfo?.UnknownWordIds && learningInfo?.UnknownWordIds?.length >= 5) {
            moveNext?.();
        }
    }, [word, addWord, saveProgress, checkRange, learningInfo?.UnknownWordIds, moveNext]);

    const handleAlreadyKnew = useCallback(() => {
        checkRange();
        addWord(LearningArray.KnownWordIds, word.ID);
        removeWord(LearningArray.UnknownWordIds, word.ID);
        removeWord(LearningArray.ToLearn, word.ID);
        saveProgress(Actions.Knew, word.ID.toString());
        moveNext();
    }, [word, addWord, saveProgress, moveNext, removeWord, checkRange]);

    const handleRemove = useCallback(() => {
        if (existWord === Actions.Knew)
            removeWord(LearningArray.KnownWordIds, word.ID);
        else {
            removeWord(LearningArray.UnknownWordIds, word.ID);
            removeWord(LearningArray.ToLearn, word.ID);
        }

    }, [word, removeWord, existWord]);

    const handleLearnNowContinue = useCallback(async () => {
        if (existWord === Actions.Knew) {
            moveNext?.();
        } else {
            open(LearnWizardStep.First, { word, wordData });
            //   moveNext?.();
        }
    }, [word, open, existWord, moveNext, wordData]);

    useEffect(() => {
        if (learningInfo?.KnownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Knew)
        else if (learningInfo?.UnknownWordIds.find(x => x === word.ID))
            setExistWord(Actions.Learn)
        else
            setExistWord('');
    }, [learningInfo?.KnownWordIds, learningInfo?.UnknownWordIds, word.ID]);


    if (!existWord) {
        return (
            <div className={styles.buttonSection}>
                <button onClick={() => handleShouldLearn()} >Should learn</button>
                <button onClick={() => handleAlreadyKnew()} className='turquoiseButton'>Already knew</button>
            </div>
        );
    }

    return (
        <section className={styles.selectSection}>
            <div className={styles.labelSection}>
                <span className={styles.label}>Your knowledge:</span>
                <div onClick={handleRemove}
                    className={`${styles.styledButton} ${existWord === Actions.Knew ? styles.knew : styles.learn}`}>
                    {existWord === Actions.Knew ? 'Already Knew' : 'Should Learn'} ▼
                </div>
            </div>
            <button className={styles.learnNowButton} onClick={handleLearnNowContinue}>
                {existWord === Actions.Knew ? 'Continue' : 'Learn Now'}
            </button>
        </section>

    );
};
