import { FC, useState } from 'react';
import { useCountries } from 'src/hook/useCountries';
import { useIFrameModal } from '../../../../hook/useIFrameModal';
import useUser from '../../../../hook/useUser';
import { GetRazorSiteUrl, IconLocation } from '../../../../utils/URLUtils';
import styles from './FriendFooter.module.scss';
import { SelectCountry } from './SelectCountry/SelectCountry';


interface FriendFooterProps {
    selectedTab: string;
}

export const FriendFooter: FC<FriendFooterProps> = ({ selectedTab }) => {
    const { userInfo } = useUser();
    const { open } = useIFrameModal();
    const [isModalOpen, setIsModalOpen] = useState(false);


 

    return (
        <div className={styles.friendFooter}>
            {selectedTab === 'friends' &&
                <button className={styles.addFriend} onClick={() => open(GetRazorSiteUrl('profile/add-friend', userInfo?.Token), 'Add a Friend')}>
                    <img src={IconLocation('user-plus', true)} alt='Plus' />
                    <span>Add Friends</span>
                </button>}
            {
                selectedTab === 'peopleNearBy' &&
                <div className={styles.locationContainer}>
                    <div className={styles.textContainer}>
                        <div className={styles.text}>
                            You see the top 20 most active users during the past 7 days. Practice more to be on top of the list!
                        </div>
                        <hr />
                    </div>
             
                    <SelectCountry />
                </div>
            }
        </div>
    );
};