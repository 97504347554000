import { useCallback, useEffect, useMemo, useState } from 'react';
import { WordData } from 'src/model/WordData';
import { GetWordDataFileUrl } from 'src/utils/URLUtils';
import { Word } from '../model/Word';
import useFetch from './useFetch';



export const useWordData = (word?: Word, autoFetch: boolean = true) => {
    const [wordData, setWordData] = useState<WordData | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const { send } = useFetch<WordData>();
    const memoizedData = useMemo(() => wordData, [wordData]);

    const fetchWordData = useCallback(async (wordToFetch?: Word) => {
        if (!wordToFetch?.ID) {
            setWordData(null);
            return;
        }

        setError(null);
        setIsLoading(true);

        try {
            const { response } = await send(GetWordDataFileUrl(wordToFetch.ID));
            setWordData(response);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to fetch word data'));
        } finally {
            setIsLoading(false);
        }
    }, [send]);

    useEffect(() => {
        if (autoFetch && word) {
            fetchWordData(word);
        }
    }, [word, autoFetch, fetchWordData]);



    return {
        wordData: memoizedData,
        isLoading,
        error,
        fetchWordData
    };
};