import { useEffect } from 'react';
import Slide from "react-slick";
import { Word } from '../../../model/Word';
import { Sense, WordData } from '../../../model/WordData';
import { SliderSettings } from '../../../utils/SliderSetting';
import SenseItem from "./SenseItem/SenseItem";
import styles from "./Senses.module.scss";

interface SensesProps {
    word: Word;
    wordData: WordData;
    setCurrentSense: (sense: Sense) => void;
}

const Senses = ({ word, wordData, setCurrentSense }: SensesProps) => {
    useEffect(() => {
        if (wordData?.Senses?.length > 0) {
            setCurrentSense(wordData.Senses[0]);
        }
    }, [wordData, setCurrentSense]);

    const sliderChange = (currentSlide: number, nextSlide: number) => {
        setCurrentSense(wordData?.Senses[nextSlide] as Sense);
    }

    if (wordData?.Senses?.length === 0) return null;

    return (
        <section className={styles.senses}>
            <Slide {...SliderSettings()} beforeChange={sliderChange}>
                {wordData?.Senses?.map((sense, index) => (
                    <SenseItem key={index} word={word} sense={sense} />
                ))}
            </Slide>
        </section>
    );
}

export default Senses;