import useUser from 'src/hook/useUser';
import styles from './ProgressBar.module.scss';
import { Answer_STEPS, Question_STEPS } from './StepsHeader';

interface Props {
    currentStep: number;
    answer?: string;
}

const ProgressBar = ({ currentStep, answer }: Props) => {
    const { isNonEnglishNativeLanguage } = useUser();
    const totalSteps = isNonEnglishNativeLanguage() ? 5 : 4;
    const skipStep2 = !isNonEnglishNativeLanguage();

    const getAdjustedStep = (index: number) => {
        if (skipStep2 && currentStep > 2) {
            return index + 1 <= currentStep - 1;
        }
        return index + 1 <= currentStep;
    };

    return (
        <div className={styles.progressBar}>
            <div className={styles.progressContainer}>
                {[...Array(totalSteps)].map((_, index) => (
                    <div
                        key={index}
                        className={`${styles.progressStep} ${getAdjustedStep(index) ? styles.active : ''
                            }`}
                    />
                ))}
            </div>
            <p className={styles.question}>{answer ? Answer_STEPS[currentStep - 1] : Question_STEPS[currentStep - 1]}</p>
        </div>
    );
};

export default ProgressBar;