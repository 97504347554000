import { useShare } from 'src/hook/useShare';
import { GetWebSiteUrl } from 'src/utils/URLUtils';
import styles from './ButtonGroupSection.module.scss';

const ButtonGroupSection = () => {
    const { share } = useShare();

    return (
        <div className={styles.section}>
            <button
                onClick={() => window.open(`${GetWebSiteUrl()}/about`, '_blank')}
                className={styles.buttonGroups}>
                About us</button>
            <button
                onClick={() => window.open(`${GetWebSiteUrl()}/privacy`, '_blank')}
                className={styles.buttonGroups}>Privacy</button>
            <button
                onClick={() => share(GetWebSiteUrl(), null, 'WordUp')}
                className={styles.buttonGroups}>Share</button>
        </div>
    );
};

export default ButtonGroupSection;