import { FC, RefObject, useState } from 'react';
import { Question } from 'src/model/Scenario';
import { IconLocation } from 'src/utils/URLUtils';
import CloseModal from './CloseModal/CloseModal';
import styles from './VideoSection.module.scss';

interface VideoSectionProps {
    videoUrl: string;
    videoRef: RefObject<HTMLVideoElement>;
    answer?: string;
    question: Question;
}

export const VideoSection: FC<VideoSectionProps> = ({ videoUrl, videoRef, answer, question }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            // Set time to 0.1 seconds to force thumbnail display
            videoRef.current.currentTime = 0.1;
        }
    };

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };

    return (
        <div className={styles.videoContainer}>
            <span
                className={styles.close} onClick={() => setIsModalOpen(true)}>
                <img src={IconLocation('x')} alt="close" />
            </span>
            <video
                ref={videoRef}
                src={videoUrl}
                className={styles.video}
                playsInline
                preload="metadata"
                onLoadedMetadata={handleLoadedMetadata}
                onClick={handleVideoClick}
            />
            <CloseModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                question={question}
                answer={answer}
            />
        </div>
    );
}; 