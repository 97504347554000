import { useEffect, useState } from 'react';
import { useLearning } from 'src/hook/useLearning';
import { useWords } from 'src/hook/useWords';
import { Word } from 'src/model/Word';
import { WordList } from 'src/model/WordList';
import { QueryString } from 'src/utils/QueryStringUtils';
import { WordListPageContent } from './WordListPageContent/WordListPageContent';
import { WordListPageHeader } from './WordListPageHeader/WordListPageHeader';

export const WordListPage = () => {
    const [wordList, setWordList] = useState<WordList>();
    const { learningInfo } = useLearning();
    const [words, setWords] = useState<Word[]>([]);
    const { getWordsByWordList } = useWords();

    useEffect(() => {
        const fetchData = async () => {
            const wordListId = QueryString('id');
            if (!wordListId) return;
            const wordList = learningInfo?.WordLists.find(x => x.ID === wordListId);
            if (!wordList) return;
            setWordList(wordList);
            setWords(getWordsByWordList(wordList.WordListData));
        }
        fetchData();
    }, [learningInfo?.WordLists, getWordsByWordList]);

    if (!wordList) return null;

    return (
        <article className='fullPage'>
            <WordListPageHeader wordList={wordList} />
            <WordListPageContent words={words} />
        </article>
    );
};
