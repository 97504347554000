import { Fragment, useCallback, useState } from 'react';
import { WordModal } from 'src/components/ui/GlobalComponents/WordModal/WordModal';
import { Word } from 'src/model/Word';
import { useWords } from './useWords';


export const useSmartWord = (hasButton: boolean = false) => {
    const { findClosestWord } = useWords();
    const [modalWord, setModalWord] = useState<Word | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleWordClick = useCallback((wordText: string) => {
        const word = findClosestWord(wordText);
        if (!word) return;
        setModalWord(word);
        setIsModalOpen(true);
    }, [findClosestWord]);


    const HighlightWord = useCallback((
        text: string | null = '',
        wordToHighlight: string | null = '',
        highlightColor: string = '#AE24F6'
    ) => {
        if (!text || !wordToHighlight) return <>{text}</>;

        text = text.trim();
        text = text.charAt(0).toUpperCase() + text.slice(1);

        const regex = new RegExp(`(${wordToHighlight})`, 'gi');
        const parts = text.split(regex);

        return (
            <>
                {parts.map((part, index) => {
                    const isHighlighted = part.toLowerCase() === wordToHighlight.toLowerCase();
                    const words = part.split(/\s+/);

                    if (isHighlighted) {
                        return (
                            <span
                                key={index}
                                style={{ color: highlightColor, cursor: 'pointer' }}
                                onDoubleClick={() => handleWordClick(part)}
                            >
                                {part}
                            </span>
                        );
                    }

                    return words.map((word, wordIndex) => (
                        <Fragment key={`${index}-${wordIndex}`}>
                            {wordIndex > 0 && ' '}
                            {word.length > 1 ?
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onDoubleClick={() => handleWordClick(word)}
                                >
                                    {word}
                                </span> :
                                word
                            }
                        </Fragment>
                    ));
                })}
                {modalWord && <WordModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    word={modalWord}
                    hasButton={hasButton}
                />}
            </>
        );
    }, [handleWordClick, isModalOpen, modalWord, hasButton]);

    const HighlightTwoWords = useCallback((
        text: string | null = '',
        word1: string | null = '',
        word2: string | null = '',
        color1: string = '#AE24F6',
        color2: string = '#32BDD2'
    ) => {

        if (!text) return <>{text}</>;

        text = text.replace(/^\s+|\s+$/g, '');
        text = text.charAt(0).toUpperCase() + text.slice(1);

        const regex1 = word1 ? new RegExp(`(${word1})`, 'gi') : null;
        const regex2 = word2 ? new RegExp(`(${word2})`, 'gi') : null;

        let parts: string[] = [text];

        if (regex1) {
            parts = parts.flatMap(part => part.split(regex1!));
        }

        if (regex2) {
            parts = parts.flatMap(part => part.split(regex2!));
        }

        return (
            <>
                {parts.map((part, index) => {
                    if (word1 && part.toLowerCase() === word1.toLowerCase()) {
                        return (
                            <span
                                key={index}
                                style={{ color: color1, cursor: 'pointer' }}
                                onDoubleClick={() => handleWordClick(part)}
                            >
                                {part}
                            </span>
                        );
                    }
                    if (word2 && part.toLowerCase() === word2.toLowerCase()) {
                        return (
                            <span
                                key={index}
                                style={{ color: color2, cursor: 'pointer' }}
                                onDoubleClick={() => handleWordClick(part)}
                            >
                                {part}
                            </span>
                        );
                    }

                    // Split non-highlighted parts into clickable words
                    const words = part.split(/\s+/);
                    return words.map((word, wordIndex) => (
                        <Fragment key={`${index}-${wordIndex}`}>
                            {wordIndex > 0 && ' '}
                            {word.length > 1 ?
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onDoubleClick={() => handleWordClick(word)}
                                >
                                    {word}
                                </span> :
                                word
                            }
                        </Fragment>
                    ));
                })}
                {modalWord && <WordModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    word={modalWord}
                    hasButton={hasButton}
                />}
            </>
        );
    }, [handleWordClick, isModalOpen, modalWord, hasButton]);

    return {
        HighlightWord,
        HighlightTwoWords,
    };
};