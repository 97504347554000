import { FC, useState } from 'react';
import { IconLocation } from '../../../../../utils/URLUtils';
import Modal from '../../../../ui/Modal/Modal';
import styles from './FinishModal.module.scss';
interface FinishModalProps {
    finishText: string;
}

const FinishModal: FC<FinishModalProps> = ({ finishText }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleFinishClick = () => {
        // setIsModalOpen(true);
    };

    return (
        <>
            <div className={styles.finishModal} onClick={handleFinishClick}>{finishText}</div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalHeader}>
                        <h2>Best Translations</h2>
                        <img src={IconLocation('x')} alt="close" onClick={() => setIsModalOpen(false)} />
                    </div>
                    <div className={styles.modalBody}></div>
                    <div className={styles.modalFooter}>
                        <input type="text"
                            placeholder="None of the above? add your own ..." />
                        <button>Submit</button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default FinishModal;