import styles from './CreateCustomList.module.scss';
import { CustomListForm } from './CustomListForm/CustomListForm';
import { CustomListHeader } from './CustomListHeader/CustomListHeader';

export const CreateCustomList = () => {
    return (
        <section className={`fullPage ${styles.createCustomList}`}>
            <CustomListHeader />
            <CustomListForm />
        </section>
    );
};
