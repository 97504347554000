import { useCallback, useState } from "react";
import { useTranslate } from "src/hook/useTranslate";
import useUser from "src/hook/useUser";
import { isRTL } from "src/utils/DirectionUtils";
import { ImageLocation } from "src/utils/URLUtils";
import styles from "./Flag.module.scss";

interface FlagProps {
    text: string;
    isDark?: boolean;
}

const Flag: React.FC<FlagProps> = ({ text, isDark }) => {
    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const [showFlag, setShowFlag] = useState(isNonEnglishNativeLanguage());
    const [translateText, setTranslateText] = useState<string | null>(null);
    const { translate, isLoading } = useTranslate();

    const handleFlagClick = useCallback(async () => {
        const response = await translate(text);
        setShowFlag(false);
        setTranslateText(response);
    }, [text, translate, setTranslateText, setShowFlag]);



    if (translateText)
        return (
            <p
                className={`${styles.translateText} ${isDark ? styles.dark : ''}`}
                dir={isRTL(translateText) ? 'rtl' : 'ltr'}
            >
                {translateText}
            </p>
        );
    else if (showFlag)
        return (
            <img
                src={ImageLocation(`LangFlags/${userInfo?.NativeLanguageCode}.webp`)}
                alt='volume'
                onClick={handleFlagClick}
                className={`${styles.flag} ${isLoading ? styles.zoom : ''}`}
            />
        );
    else
        return null;
};

export default Flag;