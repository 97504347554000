import { FC, useState } from 'react';
import useUser from '../../../../../hook/useUser';
import { CardBackgroundOption } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import { SenseImageLocation, SenseVideoLocation } from '../../../../../utils/URLUtils';
import styles from './VideoSection.module.scss';

interface VideoSectionProps {
    word: Word;
}

const VideoSection: FC<VideoSectionProps> = ({ word }) => {
    const [videoError, setVideoError] = useState(false);
    const { userInfo } = useUser();

    if (!videoError && userInfo?.CardBackground === CardBackgroundOption.VideoOrImage) {
        return (
            <video
                className={styles.coverImage}
                autoPlay
                onError={() => setVideoError(true)}
                src={SenseVideoLocation(word.PrimarySenseId)}
            />
        );
    }

    else
        return (
            <img
                className={styles.coverImage}
                src={SenseImageLocation(word.PrimarySenseId)}
                alt={word.Text}
            />
        );
};

export default VideoSection;