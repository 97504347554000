import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslate } from 'src/hook/useTranslate';
import { useWordTranslate } from 'src/hook/useWordTranslate';
import useUser from '../../../../hook/useUser';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import PlaySection from '../../../ui/GlobalComponents/PlaySection/PlaySection';
import DefinitionSection from './DefinitionSection/DefinitionSection';
import FinishModal from './FinishModal/FinishModal';
import styles from './TextContent.module.scss';

interface TextContentProps {
    word: Word;
    wordData: WordData;
    autoPlay?: boolean;
}

const TextContent: FC<TextContentProps> = ({ word, wordData, autoPlay = false }) => {
    const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);
    const { userInfo, isNonEnglishNativeLanguage } = useUser();
    const [showFlag, setShowFlag] = useState(true);
    const [translateDefinition, setTranslateDefinition] = useState<string | null>(null);
    const [translateExample, setTranslateExample] = useState<string | null>(null);
    const { getTranslateBySenseId } = useWordTranslate(userInfo?.NativeLanguageCode);
    const { translateMultiple, isLoading } = useTranslate();


    const handleFlagClick = useCallback(async () => {
        const results =
            await translateMultiple([primarySense?.de || '', primarySense?.ex || '']);
        if (results && results.length > 0) {
            setShowFlag(false);
            setTranslateDefinition(results[0]);
            setTranslateExample(results[1]);
        }
    }, [primarySense, translateMultiple]);

    useEffect(() => {
        if (!isNonEnglishNativeLanguage()) {
            setShowFlag(false);
        }
    }, [isNonEnglishNativeLanguage]);


    return (
        <div className={styles.textContentWrapper}>
            <div className={styles.textContent} >
                <PlaySection
                    type={primarySense?.ty}
                    typeClass={styles.type}
                    audioText={`${primarySense?.de}. for example. ${primarySense?.ex}`}
                    showFlag={showFlag}
                    nativeLanguageCode={userInfo?.NativeLanguageCode}
                    onFlagClick={handleFlagClick}
                    isLoading={isLoading}
                    autoPlay={autoPlay}
                />

                <DefinitionSection
                    definition={primarySense?.de}
                    example={primarySense?.ex}
                    translateDefinition={translateDefinition}
                    translateExample={translateExample}
                    wordText={word.Text}
                />
                <FinishModal finishText={getTranslateBySenseId(word?.PrimarySenseId)} />
            </div>
        </div>
    );
};

export default TextContent;