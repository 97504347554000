import { FC, useState } from 'react';
import { Word } from 'src/model/Word';
import { Sense } from 'src/model/WordData';
import useUser from '../../../../hook/useUser';
import { CardBackgroundOption } from '../../../../model/enum/enums';
import { SenseImageLocation, SenseVideoLocation } from '../../../../utils/URLUtils';
import styles from './VideoSection.module.scss';

interface VideoSectionProps {
    word: Word;
    sense: Sense;
}

const VideoSection: FC<VideoSectionProps> = ({ word, sense }) => {
    const [videoError, setVideoError] = useState(false);
    const { userInfo } = useUser();

    if (!videoError && userInfo?.CardBackground === CardBackgroundOption.VideoOrImage) {
        return (
            <video
                className={styles.coverImage}
                autoPlay
                onError={() => setVideoError(true)}
                src={SenseVideoLocation(sense.id)}
            />
        );
    }

    else
        return (
            <img
                className={styles.coverImage}
                src={SenseImageLocation(sense.id)}
                alt={word.Text}
            />
        );
};

export default VideoSection;