import { useEffect, useState } from 'react';
import LessonLottie from 'src/components/ui/GlobalComponents/LessonLottie/LessonLottie';
import { useAudio } from 'src/hook/useAudio';
import useAudioPlayer from 'src/hook/useAudioPlayer';
import { useComponentModal } from 'src/hook/useComponentModal';
import { useLearning } from 'src/hook/useLearning';
import { useScenario } from 'src/hook/useScenario';
import { AnimationType } from 'src/model/enum/enums';
import { getDirection } from 'src/utils/DirectionUtils';
import { isEqualNormalizeText } from 'src/utils/NormalizeText';
import { GetSoundUrl } from 'src/utils/URLUtils';
import { getVoice } from 'src/utils/VoiceUtils';
import { Question } from '../../../../model/Scenario';
import styles from './FinalWizard.module.scss';
import LexiModal from './LexiModal/LexiModal';
import WrongAnswerModal from './WrongAnswerModal/WrongAnswerModal';

interface Props {
    question: Question;
    onContinue: () => void;
    translateText?: string;
    answer?: string;
    onPlay: () => void;
    voice?: string;
}

const FinalWizard = ({ question, onContinue, translateText, answer, onPlay, voice }: Props) => {
    const [isCorrect, setIsCorrect] = useState(false);
    const { playAudio } = useAudio();
    const { play } = useAudioPlayer();
    const [showWrongAnimation, setShowWrongAnimation] = useState(false);
    const [writtenText, setWrittenText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLexiModalOpen, setIsLexiModalOpen] = useState(false);
    const title = answer ? answer : question.q;
    const { learningInfo } = useLearning();
    const { open, startClosing } = useComponentModal();
    const [played, setPlayed] = useState(false);
    const { areAllAnswersCompleted, addAnswer } = useScenario();


    const handleCheck = () => {
        const isAnswerCorrect = isEqualNormalizeText(writtenText, title);
        if (!isAnswerCorrect) {
            setShowWrongAnimation(true);
            setTimeout(() => setShowWrongAnimation(false), 500);
            playAudio(GetSoundUrl('IncorrectAnswer'));
            setTimeout(() => setIsModalOpen(true), 1000); // Delayed modal opening
        }
        else {
            setIsCorrect(isAnswerCorrect);
            playAudio(GetSoundUrl('CorrectAnswer'));
        }
    };

    const handleContinue = async () => {
        if (!answer) {
            onContinue();
            return;
        }

        if (learningInfo?.Lessons.Answers.length === 0
            && learningInfo?.Lessons.CompletedContext.length === 0) {
            setIsLexiModalOpen(true);
        }

        addAnswer(question, answer);

        const allAnswersCompleted = await areAllAnswersCompleted(question, answer);
        if (allAnswersCompleted) {
            startClosing();
        } else {
            open('Answers', { question, isWaiting: true }, AnimationType.SlideRight);
        }
    }

    useEffect(() => {
        if (played) return;


        const voicePerson = getVoice(voice, 0.8);
        if (answer)
            play(title, voicePerson);
        else
            onPlay();

        setPlayed(true);
    }, [title, play, played, onPlay, answer, voice]);



    return (
        <section className={styles.finalWizard}>
            {translateText &&
                <h1 className={styles.translateText} dir={getDirection(translateText)}>{translateText}</h1>}

            <textarea className={`${styles.textInput} ${showWrongAnimation ? styles.wrong : ''}`} value={writtenText}
                onChange={(e) => setWrittenText(e.target.value)} />

            {isCorrect && (
                <LessonLottie />
            )}
            <div className={styles.buttonSections}>
                {!isCorrect && <button className='secondaryButton' onClick={handleContinue}
                >Skip</button>}
                <button
                    className={styles.checkButton}
                    onClick={isCorrect ? handleContinue : handleCheck}
                >
                    {isCorrect ? 'Continue' : 'Check'}
                </button>
            </div>
            <WrongAnswerModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <LexiModal
                isModalOpen={isLexiModalOpen}
                setIsModalOpen={setIsLexiModalOpen}
            />
        </section>
    );
};

export default FinalWizard;
