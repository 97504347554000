import styles from './MyProgress.module.scss';
import ProgressContent from './ProgressContent/ProgressContent';
const MyProgress = () => {
    return (
        <section className={styles.myProgress}>
            <h2>My Progress</h2>
            <div className={styles.progressContainer}>
                <ProgressContent />
            </div>
        </section>
    );
};

export default MyProgress;