import { useState } from 'react';
import useAudioPlayer from 'src/hook/useAudioPlayer';
import { IconLocation } from 'src/utils/URLUtils';
import { getVoice } from 'src/utils/VoiceUtils';
import { Question } from '../../../../model/Scenario';
import styles from './FirstWizard.module.scss';

interface Props {
    question: Question;
    onContinue: () => void;
    onPlay: () => void;
    answer?: string;
    voice?: string;
}

const FirstWizard = ({ question, onContinue, onPlay, answer, voice }: Props) => {
    const [isPlayed, setIsPlayed] = useState(false);
    const title = answer ? answer : question.q;
    const { play } = useAudioPlayer();

    const handlePlay = () => {

        const voicePerson = getVoice(voice, 0.8);

        if (answer)
            play(title, voicePerson);
        else
            onPlay();

        setIsPlayed(true);
    }



    return (
        <section className={styles.firstWizard}>
            <h1 className={styles.questionText}>{title}</h1>
            <img src={IconLocation('speaker-square')} onClick={handlePlay}
                alt={question.q} className={styles.questionImg} />
            <button onClick={onContinue} disabled={!isPlayed}
                className={styles.continueButton}>Continue</button>
        </section>
    );
};

export default FirstWizard;
