import { useCallback, useEffect, useState } from 'react';
import LessonLottie from 'src/components/ui/GlobalComponents/LessonLottie/LessonLottie';
import { useAudio } from 'src/hook/useAudio';
import { getDirection } from 'src/utils/DirectionUtils';
import { GetSoundUrl } from 'src/utils/URLUtils';
import { Question } from '../../../../model/Scenario';
import styles from './ThirdWizard.module.scss';

interface Props {
    question: Question;
    onContinue: () => void;
    translateText?: string;
    answer?: string;
}

const ThirdWizard = ({ question, onContinue, translateText, answer }: Props) => {
    const PUNCTUATION_PATTERN = /[.?]$/;
    const SPECIAL_WORDS = new Set(['I', "I'm"]);
    const [words, setWords] = useState<string[]>([]);
    const [orderedWords, setOrderedWords] = useState<string[]>([]);
    const [usedWords, setUsedWords] = useState<Map<string, number>>(new Map());
    const [isWrong, setIsWrong] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { playAudio } = useAudio();
    const title = answer ? answer.trim() : question.q.trim();


    const getWordCount = (word: string, wordArray: string[]) => {
        return wordArray.filter(w => w === word).length;
    };

    useEffect(() => {
        const questionText = title;
        const punctuation = questionText.match(PUNCTUATION_PATTERN)?.[0] || '';
        const wordsArray = questionText.replace(PUNCTUATION_PATTERN, '')
            .trim()
            .split(' ')
            .filter(word => word !== '')
            .map(word => SPECIAL_WORDS.has(word) ? word : word.toLowerCase());

        const shuffledWords = [...wordsArray].sort(() => Math.random() - 0.5);

        setWords(shuffledWords);
        const newOrderedWords = new Array(wordsArray.length).fill('');
        if (punctuation) {
            newOrderedWords[newOrderedWords.length] = punctuation;
        }
        setOrderedWords(newOrderedWords);
        setUsedWords(new Map());
    }, [title, answer]);

    const handleDragStart = (e: React.DragEvent, word: string, fromDropZone?: boolean, index?: number) => {
        e.dataTransfer.setData('word', word);
        e.dataTransfer.setData('fromDropZone', fromDropZone ? 'true' : 'false');
        if (fromDropZone && index !== undefined) {
            e.dataTransfer.setData('fromIndex', index.toString());
            // Don't clear the word immediately to prevent loss during drag
        }
    };

    const handleDrop = (e: React.DragEvent, index: number) => {
        e.preventDefault();
        if (index === orderedWords.length - 1) return;

        const word = e.dataTransfer.getData('word');
        const fromDropZone = e.dataTransfer.getData('fromDropZone') === 'true';
        const fromIndex = parseInt(e.dataTransfer.getData('fromIndex'));
        const newOrderedWords = [...orderedWords];

        // If coming from another dropzone position, clear the original position
        if (fromDropZone && !isNaN(fromIndex)) {
            newOrderedWords[fromIndex] = '';
        }

        // Clear the target position if it's occupied
        if (newOrderedWords[index]) {
            setUsedWords(prev => {
                const updated = new Map(prev);
                const count = updated.get(newOrderedWords[index]) || 0;
                if (count <= 1) {
                    updated.delete(newOrderedWords[index]);
                } else {
                    updated.set(newOrderedWords[index], count - 1);
                }
                return updated;
            });
        }

        newOrderedWords[index] = word;
        setOrderedWords(newOrderedWords);

        // Only update usedWords if the word comes from the word bank
        if (!fromDropZone) {
            setUsedWords(prev => {
                const updated = new Map(prev);
                const count = updated.get(word) || 0;
                updated.set(word, count + 1);
                return updated;
            });
        }
    };

    const handleWordClick = (word: string) => {
        if ((usedWords.get(word) || 0) >= getWordCount(word, words)) return;

        const emptyIndex = orderedWords.findIndex(w => !w);
        if (emptyIndex !== -1 && emptyIndex !== orderedWords.length - 1) {
            const newOrderedWords = [...orderedWords];
            newOrderedWords[emptyIndex] = word;
            setOrderedWords(newOrderedWords);
            setUsedWords(prev => {
                const updated = new Map(prev);
                const count = updated.get(word) || 0;
                updated.set(word, count + 1);
                return updated;
            });
        }
    };

    const handleBoxClick = (index: number) => {
        if (index === orderedWords.length - 1 || showSuccess) return;
        if (orderedWords[index]) {
            const word = orderedWords[index];

            // Find the first empty position to the left
            let targetIndex = orderedWords.findIndex(w => !w);
            if (targetIndex === -1 || targetIndex > index) {
                // If no empty space to the left, find first empty space to the right
                targetIndex = orderedWords.slice(index + 1).findIndex(w => !w);
                if (targetIndex !== -1) {
                    targetIndex += index + 1;
                }
            }

            // If found a valid target position, move the word
            if (targetIndex !== -1) {
                const newOrderedWords = [...orderedWords];
                newOrderedWords[index] = '';
                newOrderedWords[targetIndex] = word;
                setOrderedWords(newOrderedWords);
            }
        }
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const handleWordBankDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const word = e.dataTransfer.getData('word');
        const fromDropZone = e.dataTransfer.getData('fromDropZone') === 'true';
        const fromIndex = parseInt(e.dataTransfer.getData('fromIndex'));

        if (fromDropZone && !isNaN(fromIndex)) {
            // Clear the word from the drop zone
            const newOrderedWords = [...orderedWords];
            newOrderedWords[fromIndex] = '';
            setOrderedWords(newOrderedWords);

            // Update used words count
            setUsedWords(prev => {
                const updated = new Map(prev);
                const count = updated.get(word) || 0;
                if (count <= 1) {
                    updated.delete(word);
                } else {
                    updated.set(word, count - 1);
                }
                return updated;
            });
        }
    };

    const isAnswerCorrect = useCallback(() => {
        const questionWords = title.replace(PUNCTUATION_PATTERN, '')
            .trim()
            .split(' ')
            .filter(word => word !== '')
            .map(word => SPECIAL_WORDS.has(word) ? word : word.toLowerCase());
        const punctuation = title.match(PUNCTUATION_PATTERN)?.[0] || '';

        const wordsCorrect = questionWords.every((word, index) => {
            const userWord = orderedWords[index];
            const expectedWord = SPECIAL_WORDS.has(word) ? word : word.toLowerCase();
            return userWord === expectedWord;
        });

        return punctuation
            ? wordsCorrect && orderedWords[orderedWords.length - 1] === punctuation
            : wordsCorrect;
    }, [title, orderedWords]);

    const checkAnswer = useCallback(() => {
        if (isAnswerCorrect()) {
            setShowSuccess(true);
            playAudio(GetSoundUrl('CorrectAnswer'));
        } else {
            setIsWrong(true);
            playAudio(GetSoundUrl('IncorrectAnswer'));
            setTimeout(() => setIsWrong(false), 500);
        }
    }, [isAnswerCorrect, playAudio]);



    useEffect(() => {
        if (!orderedWords.includes('') && !showSuccess && isAnswerCorrect()) {
            setShowSuccess(true);
            playAudio(GetSoundUrl('CorrectAnswer'));
        }
    }, [orderedWords, showSuccess, isAnswerCorrect, playAudio]);



    return (
        <section className={styles.thirdWizard}>
            <h1 className={styles.translateText} dir={getDirection(translateText)}>{translateText}</h1>

            <div className={`${styles.dropZone} ${showSuccess ? styles.success : ''}`}>
                {orderedWords.map((word, index) => (
                    <div
                        key={`box-${index}`}
                        className={`${styles.box} 
                            ${word?.match(PUNCTUATION_PATTERN) ? styles.punctuationBox : ''}
                            ${isWrong && word &&
                                index < title.split(' ').length &&
                                word === (title.split(' ')[index].replace(PUNCTUATION_PATTERN, '') === 'I'
                                    ? 'I'
                                    : title.split(' ')[index].replace(PUNCTUATION_PATTERN, '').toLowerCase()) === false
                                ? styles.incorrect : ''}
                            ${showSuccess ? styles.disabled : ''}`}
                        draggable={!!word && !showSuccess}
                        onDragStart={(e) => handleDragStart(e, word, true, index)}
                        onDrop={(e) => handleDrop(e, index)}
                        onDragOver={handleDragOver}
                        onClick={() => handleBoxClick(index)}
                    >
                        {word}
                    </div>
                ))}
            </div>

            {!showSuccess && (
                <div
                    className={styles.wordBank}
                    onDrop={handleWordBankDrop}
                    onDragOver={handleDragOver}
                >
                    {words.map((word, index) => (
                        <div
                            key={`word-${index}`}
                            draggable
                            onDragStart={(e) => handleDragStart(e, word)}
                            onClick={() => handleWordClick(word)}
                            className={`${styles.word} ${(usedWords.get(word) || 0) >= getWordCount(word, words) ? styles.used : ''}`}
                        >
                            {word}
                        </div>
                    ))}
                </div>
            )}

            {showSuccess && (
                <LessonLottie />
            )}

            <button
                className={styles.checkButton}
                onClick={showSuccess ? onContinue : checkAnswer}
                disabled={orderedWords.includes('')}
            >
                {showSuccess ? 'Continue' : 'Check'}
            </button>

        </section>
    );
};

export default ThirdWizard;
