import { useCallback, useEffect } from "react";
import { useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { GetRazorSiteUrl } from "src/utils/URLUtils";
import { LearningArray, LessonArrays } from '../model/enum/enums';
import { Learning } from '../model/Learning';
import { learningActions } from '../store/slices/learningSlice';
import { appDispatch, RootState } from '../store/store';
import useFetch from "./useFetch";
import useUser from "./useUser";

export const useLearning = (autoFetch: boolean = true) => {
    const { userInfo } = useUser();
    const { send } = useFetch<any>();
    const ac = bindActionCreators(learningActions, appDispatch);
    const { learningInfo } = useSelector((state: RootState) => state.learning);

    useEffect(() => {
        if (!learningInfo && autoFetch) {
            ac.getLearning();
        }
    }, [ac, learningInfo, autoFetch]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    const setLearningInfo = useCallback((info: Learning | null) => {
        ac.setLearning(info);
    }, [ac]);

    const updateLearningInfo = useCallback((info: Partial<Learning>) => {
        ac.updateLearning(info);
    }, [ac]);

    const addWord = useCallback((arrayName: LearningArray, wordId: number) => {
        if (learningInfo) {
            const updatedArray = Array.from(new Set([...learningInfo[arrayName], wordId])); // Add and ensure uniqueness
            updateLearningInfo({ [arrayName]: updatedArray });
        }
    }, [learningInfo, updateLearningInfo]);

    const removeWord = useCallback((arrayName: LearningArray, wordId: number) => {
        if (learningInfo) {
            const updatedArray = learningInfo[arrayName].filter(id => id !== wordId);
            updateLearningInfo({ [arrayName]: updatedArray });
        }
    }, [learningInfo, updateLearningInfo]);


    const addLesson = useCallback((arrayName: LessonArrays, value: string) => {
        if (learningInfo) {
            const updatedArray = Array.from(new Set([...learningInfo.Lessons[arrayName], value]));
            updateLearningInfo({
                Lessons: {
                    ...learningInfo.Lessons,
                    [arrayName]: updatedArray
                }
            });
        }
    }, [learningInfo, updateLearningInfo]);

    const fetchLearningData = useCallback(async (token?: string): Promise<Learning | null> => {
        const { response } = await send(GetRazorSiteUrl('learning', token ?? userInfo?.Token));
        if (!response) return null;
        setLearningInfo(response as Learning);
        return response as Learning;

    }, [userInfo?.Token, setLearningInfo, send]);


    return {
        learningInfo,
        setLearningInfo,
        updateLearningInfo,
        addWord,
        removeWord,
        addLesson,
        fetchLearningData,
        handleLogout,
    };
};