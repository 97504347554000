import { Question } from '../../../../model/Scenario';
import FinalWizard from '../FinalWizard/FinalWizard';
import FirstWizard from '../FirstWizard/FirstWizard';
import FourthWizard from '../FourthWizard/FourthWizard';
import SecondWizard from '../SecondWizard/SecondWizard';
import ThirdWizard from '../ThirdWizard/ThirdWizard';
import styles from './WizardStep.module.scss';
interface Props {
    currentStep: number;
    question: Question;
    onNextStep: () => void;
    onPlay: () => void;
    lessonQuestions: string[];
    translateText?: string;
    answer?: string;
    voice?: string;
}

const WizardStep = ({ answer, currentStep, question, onNextStep, onPlay, lessonQuestions, translateText, voice }: Props) => {
    const step = (() => {
        switch (currentStep) {
            case 1:
                return <FirstWizard
                    question={question}
                    onContinue={onNextStep}
                    onPlay={onPlay}
                    answer={answer}
                    voice={voice}
                />;
            case 2:
                return <SecondWizard
                    question={question}
                    onContinue={onNextStep}
                    lessonQuestions={lessonQuestions}
                    translateText={translateText}
                    answer={answer}
                />;
            case 3:
                return <ThirdWizard
                    question={question}
                    onContinue={onNextStep}
                    translateText={translateText}
                    answer={answer}
                />;
            case 4:
                return <FourthWizard
                    question={question}
                    onContinue={onNextStep}
                    answer={answer}
                    onPlay={onPlay}
                    voice={voice}
                />;
            case 5:
                return <FinalWizard
                    question={question}
                    onContinue={onNextStep}
                    translateText={translateText}
                    answer={answer}
                    onPlay={onPlay}
                    voice={voice}
                />;
            // case 6:
            //     return <WellDone
            //         question={question}
            //     />;
            default:
                return null;
        }
    })();
    return (
        <div key={currentStep} className={styles.wizardStep}>
            {step}
        </div>
    );
}
export default WizardStep;