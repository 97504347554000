import { useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie-player';
import Slider from 'react-slick';
import Spinner from 'src/components/ui/Spinner/Spinner';
import { useLearning } from 'src/hook/useLearning';
import { useScenario } from 'src/hook/useScenario';
import { QuestionDone } from 'src/model/Scenario';
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { QueryString } from 'src/utils/QueryStringUtils';
import { SliderSettings } from 'src/utils/SliderSetting';
import { GetottieUrl, ScenariosLocation } from 'src/utils/URLUtils';
import { LessonHeader } from './LessonHeader/LessonHeader';
import styles from './LessonPage.module.scss';
import { LessonQuestion } from './LessonQuestion/LessonQuestion';


export const LessonPage = () => {
    const [lessonQuestions, setLessonQuestions] = useState<QuestionDone[]>([]);
    const sliderRef = useRef<Slider>(null);
    const [lessonName, setLessonName] = useState<string | undefined>(undefined);
    const { learningInfo } = useLearning();
    const [initialSlide, setInitialSlide] = useState(0);
    const [questionID, setQuestionID] = useState<string | undefined>(undefined);
    const [currentSlide, setCurrentSlide] = useState(0);
    const previousConversations = useRef(learningInfo?.Lessons.Conversations);
    const [showLottie, setShowLottie] = useState(false);
    const [showPrize, setShowPrize] = useState(false);
    const { scenarios, scenarioQAs, isLoading } = useScenario();

    const goToNextSlide = useCallback(() => {
        setTimeout(() => {
            setShowPrize(false);
            sliderRef.current?.slickNext();
        }, 1500);
    }, []);

    const fetchData = useCallback(async () => {

        const id = QueryString('id');
        if (!id) return;
        setQuestionID(id);

        const questions = scenarioQAs?.filter(x => x.r === id) ?? [];
        const questionsDone = questions.map(async x => ({
            question: x,
            isDone: (learningInfo?.Lessons.CompletedContext.includes(x.r)
                || learningInfo?.Lessons.Conversations?.includes(await ToIOSafeHash(x.q)))
                ?? false
        }));
        const completedQuestions = await Promise.all(questionsDone);
        setLessonQuestions(completedQuestions);

        // Find index of first incomplete question
        const firstIncompleteIndex = completedQuestions.findIndex(q => !q.isDone);
        setCurrentSlide(firstIncompleteIndex === -1 ? 0 : firstIncompleteIndex);
        setInitialSlide(firstIncompleteIndex === -1 ? 0 : firstIncompleteIndex);



        const name = scenarios?.categories.find(x => x.ref === id.substring(0, 2))?.ctx
            .find(x => x.ref === id.substring(2, 4))?.name;
        setLessonName(name);


    }, [learningInfo?.Lessons, scenarios?.categories, scenarioQAs]);


    const updateQuestionDone = useCallback((index: number, isDone: boolean) => {
        setLessonQuestions(prev => prev.map((question, i) =>
            i === index ? { ...question, isDone } : question
        ));
    }, []);

    useEffect(() => {
        if (lessonQuestions?.length > 0) return;
        fetchData();

    }, [scenarios?.categories, fetchData, lessonQuestions?.length]);

    useEffect(() => {
        const currentConversations = learningInfo?.Lessons.Conversations;

        if (previousConversations.current?.length !== currentConversations?.length) {

            if (showLottie) {
                setShowPrize(true);
            }

            updateQuestionDone(currentSlide, true);
            goToNextSlide();
        }
        // Update the ref with current value
        previousConversations.current = currentConversations;
    }, [learningInfo?.Lessons, goToNextSlide, updateQuestionDone, currentSlide, showLottie]);



    if (isLoading || lessonQuestions?.length === 0) return <Spinner fullPage={true} />


    return (
        <section className={styles.lessonPage}>
            <LessonHeader lessonName={lessonName} />
            <div style={{ backgroundImage: `url(${ScenariosLocation(`${questionID}`)})` }}
                className={styles.questionImage} />


            <div className={styles.questionContainer}>
                <Slider ref={sliderRef} {...SliderSettings()}
                    initialSlide={initialSlide}
                    beforeChange={(_, next) => setCurrentSlide(next)}>
                    {lessonQuestions.map((questionDone, index) => (
                        <LessonQuestion key={index}
                            questionDone={questionDone}
                            currentIndex={index}
                            totalQuestions={lessonQuestions.length}
                            setShowLottie={setShowLottie}
                        />
                    ))}
                </Slider>
                {showPrize && <div className={styles.lottieContainer}>
                    <Lottie
                        path={GetottieUrl(`Celebrate/${Math.random() < 0.5 ? '4' : '5'}`)}
                        play
                        className={styles.lottie}
                    />
                </div>}
            </div>
        </section>
    );
};
