import React, { useEffect, useState } from 'react';
import useUser from 'src/hook/useUser';
import { Examples } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import { GetAuthorImageUrl } from 'src/utils/URLUtils';
import { QuoteData, VideosData, WisdomData, WordData } from '../../../model/WordData';
import { shuffleArray } from '../../../utils/ArrayUtils';
import { FactDataParser, VideoDataParser, WisdomDataParser, WordDataParser } from '../../../utils/WordDataParser';
import styles from './DataView.module.scss';
import FooterIcons from './FooterIcons/FooterIcons';
import { Quote } from './Quote/Quote';
import { Video } from './Video/Video';
import { Wisdom } from './Wisdom/Wisdom';


interface DataViewProps {
    wordData: WordData;
    word: Word;
}

export const DataView: React.FC<DataViewProps> = ({ wordData, word }) => {
    const [quotesAndFacts, setQuotesAndFacts] = useState<(QuoteData)[]>([]);
    const [videos, setVideos] = useState<VideosData[]>([]);
    const [wisdom, setWisdom] = useState<WisdomData[]>([]);
    const { userInfo } = useUser();


    useEffect(() => {

        const parsedVideos = VideoDataParser.parseVideos(wordData.Videos);
        setVideos(parsedVideos);

        const parsedWisdom = WisdomDataParser.parseWisdom(wordData.Wisdom);
        setWisdom(parsedWisdom);

        const parsedQuotes = WordDataParser.parseQuotes(wordData.Quotes);
        const parsedFacts = FactDataParser.parseFacts(wordData.Facts);

        const mergedAndShuffled = shuffleArray([...parsedQuotes, ...parsedFacts]);
        setQuotesAndFacts(mergedAndShuffled);
    }, [wordData]);

    const generateItems = () => {
        const items = [];
        let quoteIndex = 0;
        let videoIndex = 0;
        let wisdomIndex = 0;
        let imageUrl = '';
        let definition = '';
        let title = '';
        let videoUrl = '';
        for (let i = 0; i < 15; i++) {
            // Handle Quotes
            if (i % 3 === 0 && quoteIndex < quotesAndFacts.length && userInfo?.Examples?.includes(Examples.Quotes)) {
                imageUrl = quotesAndFacts[quoteIndex].ImageUrl;
                definition = quotesAndFacts[quoteIndex].Description;
                title = quotesAndFacts[quoteIndex].Title;
                videoUrl = '';
                items.push(
                    <Quote
                        key={`quote-${i}`}
                        data={quotesAndFacts[quoteIndex]}
                        word={word}
                    />
                );
                items.push(<FooterIcons
                    key={`footer-quote-${i}`}
                    imageUrl={imageUrl}
                    definition={definition}
                    videoUrl={videoUrl}
                    title={title} />);
                quoteIndex++;
            }
            // Handle Videos
            else if (i % 3 === 1 && videoIndex < videos.length && userInfo?.Examples?.includes(Examples.YouTube)) {
                definition = videos[videoIndex].Description;
                title = videos[videoIndex].Title;
                imageUrl = '';
                videoUrl = videos[videoIndex].VideoUrl;
                items.push(
                    <Video
                        key={`video-${i}`}
                        data={videos[videoIndex]}
                        word={word}
                    />
                );
                items.push(<FooterIcons
                    key={`footer-video-${i}`}
                    imageUrl={imageUrl}
                    definition={definition}
                    videoUrl={videoUrl}
                    title={title} />);
                videoIndex++;
            }
            // Handle Wisdom
            else if (i % 3 === 2 && wisdomIndex < wisdom.length && userInfo?.Examples?.includes(Examples.WordsOfWisdom)) {
                imageUrl = GetAuthorImageUrl('lexi');
                definition = wisdom[wisdomIndex].Description;
                title = '';
                videoUrl = '';
                items.push(
                    <Wisdom
                        key={`wisdom-${i}`}
                        data={wisdom[wisdomIndex]}
                        word={word}
                    />
                );
                items.push(<FooterIcons
                    key={`footer-wisdom-${i}`}
                    imageUrl={imageUrl}
                    definition={definition}
                    videoUrl={videoUrl}
                    title={title} />);
                wisdomIndex++;
            }
        }
        return items;
    }

    return (
        <div className={styles.dataView}>
            {generateItems()}
        </div>
    );
};

export default DataView;