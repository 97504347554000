import { configureStore } from "@reduxjs/toolkit";
import componentModalReducer from './slices/componentModalSlice';
import countriesReducer from './slices/countriesSlice';
import discoverReducer from './slices/discoverSlice';
import iframeReducer from './slices/iframeSlice';
import learningReducer from './slices/learningSlice';
import learnWizardReducer from './slices/learnWizardSlice';
import noteReducer from './slices/noteSlice';
import scenarioReducer from './slices/scenarioSlice';
import socialReducer from './slices/socialSlice';
import toastReducer from './slices/toastSlice';
import userReducer from './slices/userSlice';
import wordsReducer from './slices/wordsSlice';
import wordTranslateReducer from './slices/wordTranslateSlice';
export const store = configureStore({
    reducer: {
        user: userReducer,
        learning: learningReducer,
        social: socialReducer,
        iframe: iframeReducer,
        componentModal: componentModalReducer,
        toast: toastReducer,
        learnWizard: learnWizardReducer,
        words: wordsReducer,
        translates: wordTranslateReducer,
        countries: countriesReducer,
        scenario: scenarioReducer,
        note: noteReducer,
        discover: discoverReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Export dispatch instance if needed
export const appDispatch = store.dispatch;