import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chevron } from 'src/components/ui/Chevron/Chevron';
import styles from './Header.module.scss';

interface HeaderProps {
    onBack?: () => void;
}

export const Header: FC<HeaderProps> = ({ onBack }) => {
    const navigate = useNavigate();

    return (
        <header className={styles.header}>
            <Chevron type="left" size={34}
                onClick={() => navigate(-1)} />
            <h1 className={styles.title}>Search</h1>
        </header>
    );
};
