import React, { useRef } from 'react';
import AudioPlayer from 'src/components/ui/GlobalComponents/AudioPlayer/AudioPlayer';
import Divider from 'src/components/ui/GlobalComponents/Divider/Divider';
import { useShare } from 'src/hook/useShare';
import { GetWebSiteUrl, IconLocation, ImageLocation } from 'src/utils/URLUtils';
import styles from './FooterIcons.module.scss';

interface FooterIconsProps {
    imageUrl: string;
    definition: string;
    title: string;
    videoUrl: string;
}

const FooterIcons: React.FC<FooterIconsProps> = ({ imageUrl, definition, title, videoUrl }) => {
    const { share, isLoading } = useShare();
    const footerRef = useRef<HTMLDivElement>(null);
    const handleExplain = () => console.log('Explain');
    const handleDiscuss = () => console.log('Discuss');


    const handleShare = () => {
        // Get the previous sibling element (Quote component)
        const quoteElement = footerRef.current?.previousElementSibling?.previousElementSibling as HTMLElement;
        const text = imageUrl ? `${title} : ${definition} \n ${GetWebSiteUrl()}` : `Via WordUp Vocabulary \n ${videoUrl}`;
        share(text, quoteElement);
    };

    return (
        <>
            <Divider noMargin={true} />
            <div className={styles.footerIcons} ref={footerRef} >
                {imageUrl && (
                    <>
                        <div className={styles.iconDiv}>
                            <AudioPlayer text={definition} imageSize='25px' />
                            <span className={styles.label}>Listen</span>
                        </div>

                        <div className={styles.iconDiv} onClick={handleExplain}>
                            <img src={ImageLocation('Lexi/Lexi-General.png')}
                                alt="Explain" className={styles.icon} />
                            <span className={styles.label}>Explain</span>
                        </div>

                        <div className={styles.iconDiv} onClick={handleDiscuss}>
                            <img src={imageUrl} alt="Discuss" className={styles.icon} />
                            <span className={styles.label}>Discuss</span>
                        </div>
                    </>
                )}

                <div className={styles.iconDiv} onClick={handleShare}>
                    <img src={IconLocation('share')} alt="Share"
                        className={`${styles.icon} ${isLoading ? styles.zoom : ''}`} />
                    <span className={styles.label}>Share</span>
                </div>
            </div>
            <Divider thick={true} noMargin={true} />
        </>
    );
};

export default FooterIcons;