import { useRef, useState } from 'react';
import { useBackHandler } from 'src/hook/global/useBackHandler';
import { useWordList } from 'src/hook/useWordList';
import { WordList } from 'src/model/WordList';
import { IconLocation } from '../../../../utils/URLUtils';
import Menu from './Menu/Menu';
import styles from './WordListPageHeader.module.scss';

interface WordListPageHeaderProps {
    wordList: WordList;
}

export const WordListPageHeader = ({ wordList }: WordListPageHeaderProps) => {
    const handleBack = useBackHandler();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const { updateWordListStats } = useWordList();

    const back = () => {
        updateWordListStats(wordList);
        handleBack('/wordlists');
    }

    return (
        <section className={styles.wordListPageHeader}>
            <img className={styles.backIcon} onClick={back}
                src={IconLocation('chevron-left')} alt='back' />
            <h1>{wordList.Title}</h1>
            <span ref={menuRef} onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={styles.menu}>•••</span>
            <Menu
                isOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                trigger={menuRef.current}
                wordList={wordList}
            />
        </section>
    );
};
