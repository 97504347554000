// Voice mapping type
type VoiceMap = {
    [key: string]: string;
};

// Voice dictionary
export const Voices: VoiceMap = {
    "Cheryl": "en-GB-Neural2-A",
    "Ella": "en-GB-Neural2-A",
    "Brenda": "en-GB-Wavenet-C",
    "Jasmine": "en-GB-Wavenet-C",
    "Martin": "en-GB-Neural2-C",
    "Ava": "en-GB-Neural2-C",
    "Rachel": "en-GB-Neural2-C",
    "Joseph": "en-GB-Neural2-B",
    "Edward": "en-GB-Neural2-B",
    "Justin": "en-GB-News-M",
    "Charles": "en-GB-News-M",
    "Carol": "en-GB-News-G",
    "Kimberly": "en-GB-News-G",
    "Michael": "en-US-News-M",
    "Anthony": "en-US-News-M",
    "Margaret": "en-GB-News-H",
    "Donna": "en-GB-News-H",
    "Nicole": "en-GB-News-H",
    "Olivia": "en-GB-Neural2-F",
    "Karen": "en-GB-Neural2-F",
    "Emily": "en-GB-Neural2-F",
    "Ashley": "en-US-Standard-F",
    "Shirley": "en-US-Standard-F",
    "Scott": "en-GB-Neural2-D",
    "Patricia": "",
    "Sofia": "",
    "Anna": "en-GB-News-I",
    "Thomas": "en-US-Standard-J",
    "Gabriel": "en-US-Standard-J",
    "Rebecca": "",
    "Avery": "",
    "Andrea": "en-US-Neural2-G",
    "Susan": "en-US-Neural2-G",
    "Hunter": "en-GB-News-J",
    "George": "en-GB-News-J",
    "Helen": "en-GB-Wavenet-A",
    "Denise": "en-GB-Wavenet-A",
    "Caleb": "en-GB-Wavenet-B",
    "Isaac": "en-GB-Wavenet-B",
    "Julian": "en-GB-News-K",
    "James": "en-GB-News-K",
    "Kevin": "en-GB-News-L",
    "John": "en-GB-News-L",
    "Lucas": "en-US-News-N",
    "Cameron": "en-US-News-N",
    "Amy": "en-GB-Standard-A",
    "Julia": "en-GB-Standard-A",
    "Sebastian": "en-GB-Standard-B",
    "Austin": "en-GB-Standard-B",
    "Hudson": "en-US-Standard-I",
    "Paul": "en-US-Standard-I",
    "Amber": "en-US-Standard-G",
    "Evelyn": "en-US-Standard-G",
    "Lisa": "en-US-Standard-H",
    "Emma": "en-US-Standard-H",
    "Frank": "en-GB-Standard-D",
    "Daniel": "en-GB-Standard-D",
    "Christian": "en-GB-Standard-D",
    "Addison": "en-GB-Standard-C",
    "Kathleen": "en-GB-Standard-C",
    "Charlotte": "en-US-News-L",
    "Sharon": "en-US-News-L",
    "Carter": "en-GB-Wavenet-D",
    "Alexander": "en-GB-Wavenet-D",
    "Patrick": "en-GB-Wavenet-D",
    "Eli": "en-US-Neural2-J",
    "Zachary": "en-US-Neural2-J",
    "Alice": "en-US-Standard-E",
    "Jayden": "en-US-Neural2-A",
    "Shawn": "en-US-Neural2-A",
    "Heather": "en-GB-Standard-F",
    "Sarah": "en-GB-Standard-F",
    "Chloe": "en-GB-Wavenet-F",
    "Pamela": "en-GB-Wavenet-F",
    "Mary": "en-GB-Wavenet-F",
    "Danielle": "en-US-News-K",
    "Carolyn": "en-US-News-K",
    "Madison": "en-US-Neural2-H",
    "Scarlett": "en-US-Neural2-H",
    "Steven": "en-US-Neural2-I",
    "Jeffrey": "en-US-Neural2-I",
    "Greg": "en-US-Neural2-I",
    "Logan": "en-US-Studio-M",
    "Wyatt": "en-US-Studio-M",
    "Jacob": "en-US-Studio-M",
    "Brian": "en-US-Neural2-D",
    "Benjamin": "en-US-Neural2-D",
    "Hannah": "en-US-Neural2-F",
    "Laura": "en-US-Neural2-F",
    "Janice": "en-US-Neural2-F",
    "Melissa": "en-US-Neural2-C",
    "Linda": "en-US-Neural2-E",
    "Stephen": "en-US-Wavenet-J",
    "Jeremiah": "en-US-Wavenet-J",
    "Luke": "en-US-Wavenet-I",
    "Ryan": "en-US-Wavenet-I",
    "Marie": "en-US-Wavenet-H",
    "Natalie": "en-US-Wavenet-H",
    "Elijah": "en-US-Standard-B",
    "Ethan": "en-US-Standard-B",
    "Michelle": "en-US-Standard-C",
    "Barbara": "en-US-Standard-C",
    "Jerry": "en-US-Standard-D",
    "Kenneth": "en-US-Standard-D",
    "Owen": "en-US-Standard-D",
    "Amanda": "en-US-Studio-O",
    "Lily": "en-US-Studio-O",
    "Dennis": "en-US-Polyglot-1",
    "Ronald": "en-US-Polyglot-1",
    "Raymond": "en-US-Standard-A",
    "Oliver": "en-US-Standard-A",
    "Betty": "en-US-Wavenet-E",
    "Angel": "en-US-Wavenet-A",
    "Jonathan": "en-US-Wavenet-A",
    "Mark": "en-US-Wavenet-B",
    "Matthew": "en-US-Wavenet-B",
    "Joan": "en-US-Wavenet-G",
    "Kathy": "en-US-Wavenet-G",
    "Elizabeth": "en-US-Wavenet-F",
    "Sandra": "en-US-Wavenet-F",
    "Nancy": "en-US-Wavenet-F",
    "Jackson": "en-US-Wavenet-D",
    "Brandon": "en-US-Wavenet-D",
    "Dorothy": "en-US-Wavenet-C"
};

// GetVoice function
export const getVoice = (name?: string, pitch: number = 0, rate: number = 0.9): string => {
    if (!name) return "";


    const voice = Voices[name];
    if (voice) {
        return voice;
        //   if (pitch === 0 && rate === 0.9) return voice;
        //   return `${voice},${pitch.toString().replace("-", "n")},${rate}`;
    }

    return "";
};
