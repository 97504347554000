import React from 'react';
import styles from './CircleChart.module.scss';

interface CircleChartProps {
  value: number; // Value between 0 and 100
  title?: string; // Added title prop
  color?: string;
  size?: number;
  ringWidth?: number;
  className?: string;
  backgroundColor?: string;
  defaultRingColor?: string;

}

const CircleChart: React.FC<CircleChartProps> = ({
  value,
  title,
  color = '#36A2EB',
  size = 200,
  ringWidth = 40,
  className,
  backgroundColor = 'white',
  defaultRingColor = '#E5E5E5'
}) => {
  const normalizedValue = Math.min(Math.max(value, 0), 100);
  const radius = size / 2;
  const center = size / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = circumference;
  const strokeDashoffset = circumference * (1 - normalizedValue / 100);

  return (
    <div className={`${styles.chartContainer} ${className || ''}`}>
      <div className={styles.circleChart}>
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          {/* Background circle */}
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill={backgroundColor}
          />

          {/* Gray background ring */}
          <circle
            cx={center}
            cy={center}
            r={radius - (ringWidth / 2)}
            fill="none"
            stroke={defaultRingColor}
            strokeWidth={ringWidth}
            className={styles.backgroundRing}
          />

          {/* Colored progress ring */}
          <circle
            cx={center}
            cy={center}
            r={radius - (ringWidth / 2)}
            fill="none"
            stroke={color}
            strokeWidth={ringWidth}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            className={styles.progressRing}
            transform={`rotate(-90 ${center} ${center})`}
          />
        </svg>
      </div>
      {title && <div className={styles.title}>{title}</div>}
    </div>
  );
};

export default CircleChart;