import { IconLocation } from '../../../../utils/URLUtils';
import Divider from '../../../ui/GlobalComponents/Divider/Divider';
import styles from './NavigationSection.module.scss';

interface NavigationSectionProps {
    icon: string;
    text: string;
    hasArrow?: boolean;
    onClick?: () => void;
    thick?: boolean;
    isLoading?: boolean;
}

const NavigationSection = ({ icon, text, onClick, hasArrow, thick, isLoading }: NavigationSectionProps) => (
    <>
        <div className={styles.section} onClick={onClick}>
            <div className={styles.row}>
                <img
                    className={`${styles.icon} ${isLoading ? styles.rotating : ''}`}
                    src={IconLocation(icon)}
                    alt={text}
                />
                <span className={styles.text}>{isLoading ? 'Please wait ...' : text}</span>
            </div>
            {hasArrow && <img className={styles.arrow} src={IconLocation('chevron-right')} alt="Arrow" />}
        </div>
        <Divider thick={thick} />
    </>
);

export default NavigationSection;