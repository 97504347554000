import html2canvas from 'html2canvas';
import { useState } from 'react';
import { GetWebSiteDictionaryUrl } from 'src/utils/URLUtils';
import { useToast } from './useToasts';

export const useShare = () => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);


    const share = async (content: string, elementToCapture?: HTMLElement | null, title: string = 'Share Word') => {
        setIsLoading(true);
        let result = '';
        if (!elementToCapture) {
            result = `Learn ${content} \n ${GetWebSiteDictionaryUrl(content)}`;
        } else {
            result = content;
        }
        try {
            if (navigator.share) {
                const shareData: ShareData = {
                    title,
                    text: result,
                };

                if (elementToCapture) {
                    try {
                        const clonedElement = elementToCapture.cloneNode(true) as HTMLElement;

                        // Style modifications
                        clonedElement.style.position = 'absolute';
                        clonedElement.style.left = '-9999px';
                        clonedElement.style.width = '300px';

                        // Style paragraphs
                        const paragraphs = clonedElement.getElementsByTagName('p');
                        Array.from(paragraphs).forEach(p => {
                            p.style.maxWidth = '200px';
                            p.style.margin = 'auto';
                        });

                        // Handle YouTube iframes
                        // const iframes = clonedElement.getElementsByTagName('iframe');
                        // await Promise.all(Array.from(iframes).map(async (iframe) => {
                        //     if (iframe.src.includes('youtube.com')) {
                        //         const thumbnailDataUrl = getYouTubeThumbnail(iframe.src);
                        //         const img = document.createElement('img');
                        //         img.src = thumbnailDataUrl;
                        //         img.style.width = '100%';
                        //         img.style.height = 'auto';
                        //         img.style.maxWidth = '300px';
                        //         img.style.margin = 'auto';
                        //         img.style.display = 'block';
                        //         iframe.parentNode?.replaceChild(img, iframe);
                        //     }
                        // }));
                        // Style and process images
                        const images = clonedElement.getElementsByTagName('img');
                        await Promise.all(Array.from(images).map(async (img, index) => {
                            try {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                if (!ctx) return;
                                // Create a temporary canvas for circular clipping
                                const tempCanvas = document.createElement('canvas');
                                const tempCtx = tempCanvas.getContext('2d');
                                if (!tempCtx) return;
                                const tempImage = new Image();
                                tempImage.crossOrigin = 'anonymous';
                                // Set dimensions based on index
                                let size = 30;
                                if (images.length > 1)
                                    size = index === 0 ? 100 : 30;
                                canvas.width = size;
                                canvas.height = size;
                                tempCanvas.width = size;
                                tempCanvas.height = size;
                                const imgUrl = new URL(img.src);
                                imgUrl.searchParams.set('timestamp', Date.now().toString());
                                await new Promise((resolve, reject) => {
                                    tempImage.onload = resolve;
                                    tempImage.onerror = reject;
                                    tempImage.src = imgUrl.toString();
                                });
                                // Create circular clipping path
                                tempCtx.beginPath();
                                tempCtx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
                                tempCtx.closePath();
                                tempCtx.clip();
                                // Draw the image
                                tempCtx.drawImage(tempImage, 0, 0, size, size);
                                // Copy to final canvas
                                ctx.drawImage(tempCanvas, 0, 0);
                                img.parentNode?.replaceChild(canvas, img);
                            } catch (error) {
                                console.error('Error processing image:', error);
                            }
                        }));

                        document.body.appendChild(clonedElement);


                        const canvas = await html2canvas(clonedElement, {
                            useCORS: true,
                            allowTaint: true,
                            logging: false,
                            backgroundColor: '#ffffff'
                        });

                        document.body.removeChild(clonedElement);

                        const blob = await new Promise<Blob>((resolve) => {
                            canvas.toBlob((blob) => resolve(blob!), 'image/png', 1.0);
                        });
                        const file = new File([blob], 'screenshot.png', { type: 'image/png' });
                        shareData.files = [file];
                    } catch (error) {
                        console.error('Error capturing screenshot:', error);
                    }
                }

                await navigator.share(shareData);
            } else {
                await navigator.clipboard.writeText(result);
                toast.showSuccess('Copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return { share, isLoading };
};

const getYouTubeThumbnail = (url: string) => {
    const videoId = url.split('embed/')[1]?.split('?')[0];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};
// const getYouTubeThumbnail = async (url: string) => {
//     const videoId = url.split('embed/')[1]?.split('?')[0];
//     const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

//     // Fetch the image and convert to base64
//     try {
//         const response = await fetch(thumbnailUrl, { mode: 'no-cors' });
//         const blob = await response.blob();
//         return new Promise((resolve) => {
//             const reader = new FileReader();
//             reader.onloadend = () => resolve(reader.result as string);
//             reader.readAsDataURL(blob);
//         });
//     } catch (error) {
//         console.error('Error fetching thumbnail:', error);
//         // Return a placeholder or default image if needed
//         return '';
//     }
// }