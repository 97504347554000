export interface Word {
    ID: number;
    Rank: number;
    Text: string;
    OtherForms: string;
    PrimaryMeaning: string;
    PrimarySenseId: string;
    Type: WordType;
    AmericanPhonetic: string;
    BritishPhonetic: string;
    AllForms: string[];

}

export interface WordTranslate {
    [senseId: string]: string;
}


export enum WordType { Word = 1, Phrase = 2 }