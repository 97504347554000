import { useNavigate } from 'react-router-dom';
import { ImageLocation } from 'src/utils/URLUtils';
import styles from './EmptyLearn.module.scss';

const EmptyLearn = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/wordlists/discover?id=-1');
    }

    return (
        <div className={styles.emptyLearn}>
            <h1>Discover Words</h1>
            <img src={ImageLocation('Lexi/DailyChallengesEmptyBox.png')} alt="No words to learn" />
            <p>Uncover your knowledge map</p>
            <button onClick={handleContinue} className="turquoiseButton">Continue</button>
        </div>
    );
};

export default EmptyLearn;
