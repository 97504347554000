import { useRef, useState } from 'react';
import { useBackHandler } from 'src/hook/global/useBackHandler';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './CustomListHeader.module.scss';


export const CustomListHeader = () => {
    const handleBack = useBackHandler();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const viewMenuRef = useRef<HTMLDivElement>(null);

    const back = () => {
        handleBack('/wordlists');
    }
    return (
        <section className={styles.customListHeader}>
            <img className={styles.backIcon} onClick={back}
                src={IconLocation('chevron-left')} alt='back' />
            <h1>Create new list</h1>
        </section>
    );
};
