import { useEffect } from 'react';
import useAudioPlayer from '../../../../hook/useAudioPlayer';
import { IconLocation } from '../../../../utils/URLUtils';
import styles from './AudioPlayer.module.scss';

interface AudioPlayerProps {
    text: string;
    imageSize?: string;
    autoPlay?: boolean;
    isStop?: boolean;
}

const AudioPlayer = ({ text, imageSize = '2.2rem', autoPlay = false, isStop = false }: AudioPlayerProps) => {
    const { isPlaying, play, pause, stop } = useAudioPlayer();

    useEffect(() => {
        if (autoPlay) {
            play(text, '', true);
        }
    }, []);

    useEffect(() => {
        if (isStop) {
            stop();
        }
    }, [isStop, stop]);

    return (
        <>
            {isPlaying ? (
                <img
                    style={{ width: imageSize, height: imageSize }}
                    onClick={pause}
                    className={styles.pauseIcon}
                    src={IconLocation('pause-circle')}
                    alt="Pause"
                />
            ) : (
                <img
                    style={{ width: imageSize, height: imageSize }}
                    onClick={() => play(text)}
                    className={styles.playIcon}
                    src={IconLocation('play-circle')}
                    alt="Play"
                />
            )}
        </>
    );
};

export default AudioPlayer;