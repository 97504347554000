import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { LoadingStatus } from "../model/enum/enums";
import { SavedProgress, User } from '../model/User';
import { userActions } from '../store/slices/userSlice';
import { AppDispatch, RootState } from '../store/store';
import { insertIntoQueue } from "../utils/QueueUtils";
import { GetQueueUrl, GetRazorSiteUrl } from "../utils/URLUtils";
import useFetch from "./useFetch";

const useUser = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { send } = useFetch<any>();
    // Memoize bound actions to prevent recreating on every render
    const ac = useMemo(
        () => bindActionCreators(userActions, dispatch),
        [dispatch]
    );

    const { userInfo, isUserLoaded, status, error } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (!isUserLoaded) {
            ac.getUser();
        }
    }, [ac, isUserLoaded]);

    const setUserInfo = useCallback((info: User | null) => {
        ac.setUser(info);
    }, [ac]);



    const updateUserInfo = useCallback((info: Partial<User>) => {
        ac.updateUser(info);
    }, [ac]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    const isAuthenticated = useCallback(() => {
        return !!userInfo?.Token;
    }, [userInfo?.Token]);

    const isNonEnglishNativeLanguage = useCallback(() => {
        if (!userInfo?.NativeLanguageCode) return false;
        return userInfo.NativeLanguageCode !== 'en';
    }, [userInfo?.NativeLanguageCode]);

    const uploadProgress = useCallback(() => {
        if (!userInfo?.SavedProgress?.length) return;

        const actionsString = userInfo.SavedProgress.map(progress => `${progress.Action}:${progress.Value}`).join('|');

        // Prepare jsonData
        const jsonData = JSON.stringify({
            Token: userInfo?.Token,
            Actions: actionsString
        });

        insertIntoQueue(GetQueueUrl('SaveProgress'), jsonData);

        ac.updateUser({ SavedProgress: [] });
    }, [ac, userInfo?.SavedProgress, userInfo?.Token]);

    const saveProgress = useCallback((action: string, value: string) => {
        const newProgress: SavedProgress = { Action: action, Value: value };
        const updatedProgress = [...userInfo?.SavedProgress || [], newProgress];
        ac.updateUser({ SavedProgress: updatedProgress });

        if (updatedProgress.length >= 5) {
            uploadProgress();
        }
    }, [ac, userInfo?.SavedProgress, uploadProgress]);

    const fetchUserData = useCallback(async (token?: string): Promise<User | null> => {
        const { response } = await send(GetRazorSiteUrl('settings', token ?? userInfo?.Token));
        if (!response) return null;
        const user = { ...response, Token: token ?? userInfo?.Token };
        setUserInfo(user);
        return user;
    }, [userInfo?.Token, send, setUserInfo]);

    const updateUserInfoField = useCallback((field: keyof User, value: string | boolean | number) => {
        ac.updateUser({ [field]: value });
        saveProgress('user-data', `${field}:${value}`);
    }, [ac, saveProgress]);

    return {
        userInfo,
        setUserInfo,
        updateUserInfo,
        updateUserInfoField,
        handleLogout,
        isAuthenticated,
        uploadProgress,
        isUserLoaded,
        isNonEnglishNativeLanguage,
        saveProgress,
        fetchUserData,
        isLoading: status === LoadingStatus.LOADING,
        isError: status === LoadingStatus.FAILED,
        error
    }
};

export default useUser;