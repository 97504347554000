import { FC } from 'react';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import ImageSection from '../../ui/GlobalComponents/ImageSection/ImageSection';
import styles from './ThirdWizard.module.scss';
import ThirdWizardTextContent from './ThirdWizardTextContent/ThirdWizardTextContent';

interface ThirdWizardProps {
    word: Word;
    wordData: WordData;
}

const ThirdWizard: FC<ThirdWizardProps> = ({ word, wordData }) => {

    return (
        <div className={styles.thirdWizard}>
            <ImageSection word={word} hasRank={false} />
            <ThirdWizardTextContent
                word={word}
                wordData={wordData}
            />
        </div>
    );
};

export default ThirdWizard;