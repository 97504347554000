import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Country } from '../../model/Country';
import { LoadingStatus } from '../../model/enum/enums';
import { GetResourcesUrl } from '../../utils/URLUtils';

interface CountriesState {
    countries: Country[];
    status: LoadingStatus;
    error: string | null;
}

const initialState: CountriesState = {
    countries: [],
    status: LoadingStatus.IDLE,
    error: null
};

let isFetching = false;

export const fetchCountries = createAsyncThunk(
    'countries/fetch',
    async ({ forceRefresh = false }: { forceRefresh?: boolean } = {}, { getState }) => {
        if (isFetching) {
            return (getState() as { countries: CountriesState }).countries.countries;
        }

        const state = getState() as { countries: CountriesState };

        if (
            state.countries.status === LoadingStatus.SUCCEEDED &&
            state.countries.countries.length > 0
        ) {
            return state.countries.countries;
        }

        try {
            isFetching = true;
            const url = GetResourcesUrl('Countries.json');
            const fetchOptions: RequestInit = {
                cache: forceRefresh ? 'reload' : 'default'
            };

            const response = await fetch(url, fetchOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch countries file');
            }

            const data: Country[] = await response.json();
            return data;
        } finally {
            isFetching = false;
        }
    }
);

const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.status = LoadingStatus.LOADING;
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.status = LoadingStatus.SUCCEEDED;
                state.countries = action.payload;
                state.error = null;
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.status = LoadingStatus.FAILED;
                state.error = action.error.message ?? 'An unknown error occurred';
            });
    },
});

export default countriesSlice.reducer;