import { useCallback } from 'react';
import { store } from '../store/store';
import { useLearning } from './useLearning';
import useUser from './useUser';

export const useServiceWorker = () => {
    const { setUserInfo, userInfo } = useUser();
    const { setLearningInfo } = useLearning();

    const unregisterWorker = useCallback(async () => {
        if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (const registration of registrations) {
                await registration.unregister();
            }
            window.location.reload();
        }
    }, []);

    const registerWorker = useCallback(async () => {
        if ('serviceWorker' in navigator) {
            try {
                await navigator.serviceWorker.register('/serviceWorker.js', {
                    scope: '/'
                });

                const messageHandler = (event: MessageEvent) => {
                    const { type } = event.data;
                    // Get latest userInfo from Redux store
                    const currentUserInfo = store.getState().user.userInfo;

                    if (type === 'UPDATE_DATA') {
                        setUserInfo(event.data.userData);
                        setLearningInfo(event.data.learningData);
                    } else if (type === 'CLEAR_PROGRESS') {
                        setUserInfo({
                            ...currentUserInfo!,
                            SavedProgress: []
                        });
                    } else if (type === 'GET_SAVED_PROGRESS') {
                        if (currentUserInfo?.SavedProgress?.length && currentUserInfo?.SavedProgress?.length > 0) {
                            navigator.serviceWorker.controller?.postMessage({
                                type: 'SAVE_PROGRESS',
                                progress: currentUserInfo.SavedProgress,
                                token: currentUserInfo.Token
                            });
                        }
                    } else if (type === 'GET_USER_TOKEN') {
                        if (currentUserInfo?.Token) {
                            navigator.serviceWorker.controller?.postMessage({
                                type: 'USER_TOKEN',
                                token: currentUserInfo.Token
                            });
                        }
                    }
                };

                navigator.serviceWorker.addEventListener('message', messageHandler);
            } catch (error) {
                console.error('Service Worker registration failed:', error);
            }
        }
    }, [setUserInfo, setLearningInfo]);

    return { registerWorker, unregisterWorker };
};
