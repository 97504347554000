import { useSmartWord } from 'src/hook/useSmartWord';
import { getDirection } from 'src/utils/DirectionUtils';
import styles from './DefinitionSection.module.scss';


interface DefinitionSectionProps {
    definition?: string | null;
    example?: string | null;
    translateDefinition?: string | null;
    translateExample?: string | null;
    wordText: string;
}

const DefinitionSection = ({
    definition,
    example,
    translateDefinition,
    translateExample,
    wordText
}: DefinitionSectionProps) => {
    const { HighlightWord } = useSmartWord();


    return (
        <>
            <div className={styles.definition}>
                {definition}
            </div>

            {translateDefinition &&
                <div className={styles.translate} dir={getDirection(translateDefinition)}>
                    {translateDefinition}
                </div>}

            <div className={styles.example}>
                {HighlightWord(example, wordText)}
            </div>

            {translateExample &&
                <div className={styles.translate} dir={getDirection(translateExample)}>
                    {translateExample}
                </div>}
        </>
    );
};

export default DefinitionSection;