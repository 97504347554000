import { useState } from 'react';
import { Question } from 'src/model/Scenario';
import { IconLocation, ScenariosLocation } from 'src/utils/URLUtils';
import CloseModal from './CloseModal/CloseModal';
import styles from './ImageSection.module.scss';
interface Props {
    question: Question;
}

const ImageSection = ({ question }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className={styles.imageSection}>
            <div style={{ backgroundImage: `url(${ScenariosLocation(`${question.r}`)})` }}
                className={styles.questionImage} />
            <span
                className={styles.close} onClick={() => setIsModalOpen(true)}>
                <img src={IconLocation('x')} alt="close" />
            </span>
            <CloseModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                question={question}
            />
        </div>

    );
};

export default ImageSection;
