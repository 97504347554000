import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Question, Scenarios } from '../../model/Scenario';

interface ScenarioState {
    scenarios: Scenarios | null;
    scenarioQAs: Question[] | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: ScenarioState = {
    scenarios: null,
    scenarioQAs: null,
    isLoading: false,
    error: null
};

const scenarioSlice = createSlice({
    name: 'scenario',
    initialState,
    reducers: {
        setScenariosData: (state, action: PayloadAction<Scenarios>) => {
            state.scenarios = action.payload;
        },
        setScenarioQAs: (state, action: PayloadAction<Question[]>) => {
            state.scenarioQAs = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        }
    }
});

export const { setScenariosData, setScenarioQAs, setIsLoading, setError } = scenarioSlice.actions;
export default scenarioSlice.reducer;
