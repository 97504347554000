import styles from './Progress.module.scss';
import ProgressHeader from './ProgressHeader/ProgressHeader';
import MyProgress from './MyProgress/MyProgress';
import Friends from './Friends/Friends';
const Progress = () => {
    return (
        <article className={styles.progress}>
            <ProgressHeader />
            <MyProgress />
            <Friends />
        </article>
    );
};

export default Progress;