import { Note } from "src/model/Note";
import { Learning } from "../model/Learning";
import { Social } from "../model/Social";
import { User } from "../model/User";
import { StorageUtils } from "./BaseStorageUtils";

export const STORAGE_KEYS = {
    USER: 'user_info',
    LEARNING: 'learning_info',
    SOCIAL: 'social_info',
    NOTE: 'note_info'
} as const;

export const userStorage = new StorageUtils<User>(STORAGE_KEYS.USER);
export const learningStorage = new StorageUtils<Learning>(STORAGE_KEYS.LEARNING);
export const socialStorage = new StorageUtils<Social>(STORAGE_KEYS.SOCIAL);
export const noteStorage = new StorageUtils<Note>(STORAGE_KEYS.NOTE);