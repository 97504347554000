import React, { useState } from 'react';
import { Chevron } from 'src/components/ui/Chevron/Chevron';
import { useSmartWord } from 'src/hook/useSmartWord';
import { Word } from 'src/model/Word';
import { CompareData } from 'src/model/WordData';
import styles from './CompareItem.module.scss';

interface CompareItemProps {
    activeCompare: CompareData;
    word: Word;
}

export const CompareItem: React.FC<CompareItemProps> = ({ activeCompare, word }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { HighlightTwoWords } = useSmartWord(true);
    const splitDescription = activeCompare?.Description?.split('\n').filter(Boolean) || [];
    const hasMultipleParagraphs = splitDescription.length > 2;

    const visibleDescription = isExpanded
        ? activeCompare?.Description
        : splitDescription.slice(0, 3).join('\n');

    return (
        <div className={styles.compareItem}>
            <div className={`${styles.description} ${!isExpanded && hasMultipleParagraphs ? styles.collapsed : ''}`}>
                {HighlightTwoWords(visibleDescription, word.Text, activeCompare?.Title)}
                {hasMultipleParagraphs && !isExpanded && (
                    <div
                        className={styles.expandButton}
                        onClick={() => setIsExpanded(true)}
                    >
                        <Chevron type="down" size={44} />
                    </div>
                )}
            </div>
            <img
                className={styles.image}
                src={activeCompare?.ImageUrl}
                alt={activeCompare?.Title}
            />
        </div>
    );
};

export default CompareItem;
