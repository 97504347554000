export const contractionMap: { [key: string]: string[] } = {
    "i'm": ["i am"],
    "i'll": ["i will"],
    "i've": ["i have"],
    "i'd": ["i would", "i had"],
    "you're": ["you are"],
    "you'll": ["you will"],
    "you've": ["you have"],
    "you'd": ["you would", "you had"],
    "he's": ["he is", "he has"],
    "she's": ["she is", "she has"],
    "it's": ["it is", "it has"],
    "we're": ["we are"],
    "they're": ["they are"],
    "aren't": ["are not"],
    "won't": ["will not"],
    "can't": ["cannot", "can not"],
    "don't": ["do not"],
    "doesn't": ["does not"]
};


export const CleanText = (text: string) => {
    return text.toLowerCase()
        .trim()
        .replace(/[,.?']/g, '') // Remove comma, period, question mark, and apostrophe
        .replace(/\//g, ' ')    // Replace forward slash with space
        .replace(/\s+/g, ' ');  // Normalize whitespace
};

export const isEqualNormalizeText = (text: string, targetText: string): boolean => {
    // Remove commas and extra spaces from both texts
    let normalized = CleanText(text);
    const target = CleanText(targetText);

    console.log(normalized);
    console.log(target);
    // If texts are exactly equal after basic normalization
    if (normalized === target) {
        return true;
    }

    // Check both directions (contracted to full and full to contracted)
    for (const [contraction, fullForms] of Object.entries(contractionMap)) {
        // Check if text contains contraction
        if (normalized.includes(contraction)) {
            for (const fullForm of fullForms) {
                if (normalized.replace(contraction, fullForm) === target) {
                    return true;
                }
            }
        }
        // Check if text contains full form
        for (const fullForm of fullForms) {
            if (normalized.includes(fullForm)) {
                if (normalized.replace(fullForm, contraction) === target) {
                    return true;
                }
            }
        }
    }

    return false;
};