import { useCallback, useEffect, useRef, useState } from 'react';
import useUser from 'src/hook/useUser';
import { GetSpecificVoiceUrl, GetSpeechUrl } from '../utils/URLUtils';


const useAudioPlayer = () => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const { userInfo } = useUser();

    // Create audio element on mount
    useEffect(() => {
        audioRef.current = new Audio();
        audioRef.current.onended = () => setIsPlaying(false);

        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
            }
        };
    }, []);

    const play = useCallback(async (text: string, voice: string = '', checkAutoPronounce: boolean = false) => {
     
        if (checkAutoPronounce === true) {
            if (userInfo?.AutoPronounce === false) return;
        }
        if (isPlaying) return;

        try {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = '';
            }

            const link = voice
                ? GetSpecificVoiceUrl(voice, text)
                : GetSpeechUrl(userInfo?.Voice || 'gb', text);

            setIsPlaying(true);

            try {
                const response = await fetch(link);
                const audioUrl = await response.text();

                if (audioRef.current) {
                    audioRef.current.src = audioUrl;
                    await audioRef.current.play();
                }
            } catch (e) {
                setIsPlaying(false);
            }
        } catch (e) {
            setIsPlaying(false);
        }
    }, [userInfo, isPlaying]);

    const pause = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
        setIsPlaying(false);
    }, []);

    const stop = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        setIsPlaying(false);
    }, []);

    return {
        audioRef,
        isPlaying,
        play,
        pause,
        stop
    };
};

export default useAudioPlayer; 