import { useEffect, useState } from 'react';
import { useTranslate } from 'src/hook/useTranslate';
import { QuestionDone } from 'src/model/Scenario';
import { getDirection } from 'src/utils/DirectionUtils';
import ActionButtons from './ActionButtons/ActionButtons';
import styles from './LessonQuestion.module.scss';


interface Props {
    questionDone: QuestionDone;
    currentIndex: number;
    totalQuestions: number;
    setShowLottie: (value: boolean) => void;
}

export const LessonQuestion = ({ questionDone, currentIndex, totalQuestions, setShowLottie }: Props) => {
    const [translateText, setTranslateText] = useState<string | null>(null);
    const { translate } = useTranslate();


    useEffect(() => {
        const getTranslate = async () => {
            const response = await translate(questionDone.question.q);
            setTranslateText(response);
        }
        if (!translateText) getTranslate();
    }, [questionDone.question.q, translate, translateText]);





    return (
        <div className={styles.lessonQuestion}>
            <div className={`${styles.questionHeader} ${questionDone.isDone ? styles.done : ''}`}>
                Lesson {currentIndex + 1}/{totalQuestions}
            </div>
            <div className={styles.questionContent}>
                <h1>{questionDone.question.q}</h1>
                <p dir={getDirection(translateText)}
                    className={styles.translateText}>
                    {translateText || '\u00A0'}
                </p>
                <ActionButtons
                    questionDone={questionDone}
                    translateText={translateText}
                    setShowLottie={setShowLottie}
                />
            </div>
        </div>
    );
};
