import { FC } from 'react';
import Modal from 'src/components/ui/Modal/Modal';
import { useComponentModal } from 'src/hook/useComponentModal';
import { Question } from 'src/model/Scenario';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './CloseModal.module.scss';
interface CloseModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    question: Question;
}

const CloseModal: FC<CloseModalProps> = ({ isModalOpen, setIsModalOpen, question }) => {
    const { startClosing } = useComponentModal();

    const handleConfirm = () => {
        setIsModalOpen(false);
        startClosing();
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <div className={styles.closeModal}>
                <div className={styles.header}>
                    <h2>Sure?</h2>
                    <img src={IconLocation('x')} className={styles.close}
                        alt='close' onClick={() => setIsModalOpen(false)} />
                </div>
                <p>Do you want to quite?</p>
                <hr />
                <div className={styles.buttons}>
                    <button onClick={() => setIsModalOpen(false)}
                        className='secondaryButton'>Cancel</button>
                    <button onClick={handleConfirm}
                        className={styles.confirm}>Confirm</button>
                </div>
            </div>
        </Modal>
    );
};

export default CloseModal;