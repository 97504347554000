import { FC } from 'react';
import Modal from 'src/components/ui/Modal/Modal';
import { useLearning } from 'src/hook/useLearning';
import { useScenario } from 'src/hook/useScenario';
import useUser from 'src/hook/useUser';
import { Actions } from 'src/model/enum/enums';
import { Lessons } from 'src/model/Lessons';
import { Question } from 'src/model/Scenario';
import { ToIOSafeHash } from 'src/utils/IOSafeHash';
import { IconLocation } from 'src/utils/URLUtils';
import styles from './SkipModal.module.scss';


interface SkipModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    question: Question;
}

const SkipModal: FC<SkipModalProps> = ({ isModalOpen, setIsModalOpen, question }) => {
    const { learningInfo, updateLearningInfo } = useLearning();
    const { saveProgress } = useUser();
    const { areAllQuestionsCompleted } = useScenario();

    const handleConfirm = async () => {
        setIsModalOpen(false);

        const hashQuestion = await ToIOSafeHash(question.q);

        const answerHashes = await Promise.all(question.a.map(a =>
            ToIOSafeHash(`${question.q}|${a}`)
        ));


        const allQuestionsCompleted = await areAllQuestionsCompleted(question);
        const lessons: Lessons = {
            CompletedContext: [...(learningInfo?.Lessons.CompletedContext || []), ...(allQuestionsCompleted ? [question.r] : [])],
            Conversations: [...(learningInfo?.Lessons.Conversations || []), hashQuestion],
            Answers: [...(learningInfo?.Lessons.Answers || []), ...answerHashes]
        }

        updateLearningInfo({ Lessons: lessons });

        saveProgress(Actions.LessonQuestionAdded, hashQuestion);
        // answerHashes.forEach(answerHash => {
        //     saveProgress(Actions.LessonAnswerAdded, answerHash);
        // });

    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <div className={styles.skipModal}>
                <div className={styles.header}>
                    <h2>Already know it?</h2>
                    <img src={IconLocation('x')} className={styles.close}
                        alt='close' onClick={() => setIsModalOpen(false)} />
                </div>
                <p>Do you already know this question and all of its answers?</p>
                <hr />
                <div className={styles.buttons}>
                    <button onClick={() => setIsModalOpen(false)}
                        className='secondaryButton'>Cancel</button>
                    <button onClick={handleConfirm}
                        className={styles.confirm}>Confirm</button>
                </div>
            </div>
        </Modal>
    );
};

export default SkipModal;