import React from 'react';

interface ErrorMessageProps {
    title?: string;
    message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title = 'Error', message }) => {
    return (
        <div>
            <h1>{title}</h1>
            <p>{message}</p>
        </div>
    );
};

export default ErrorMessage;