import useUser from "./useUser";

export const useAudio = () => {

    const { userInfo } = useUser();

    const playAudio = async (url: string) => {
        if (userInfo?.PlaySound === 'off') return;
        try {
            const audio = new Audio(url);
            await audio.play();
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };
    return { playAudio };
};