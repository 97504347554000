import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { MenuPosition } from '../../../model/enum/enums';
import styles from './DropdownMenu.module.scss';

interface DropdownMenuProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    trigger: HTMLElement | null;
    position?: MenuPosition;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
    isOpen,
    onClose,
    children,
    trigger,
    position = MenuPosition.BottomLeft
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                trigger &&
                !trigger.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose, trigger]);

    const positionDropdownMenu = () => {
        if (!trigger) return {};

        const triggerRect = trigger.getBoundingClientRect();
        const modalRect = modalRef.current?.getBoundingClientRect();
        const sliderItem = trigger.closest('[class*="slideItem"]');

        if (sliderItem) {
            // Inside slider positioning
            const sliderRect = sliderItem.getBoundingClientRect();
            const positions = {
                [MenuPosition.BottomLeft]: {
                    top: `${triggerRect.bottom + 8}px`,
                    right: `${window.innerWidth - triggerRect.right}px`,
                },
                [MenuPosition.BottomRight]: {
                    top: `${triggerRect.bottom - sliderRect.top + 8}px`,
                    left: `${triggerRect.right - sliderRect.left - (modalRect?.width || 0)}px`,
                },
                [MenuPosition.TopLeft]: {
                    bottom: `${sliderRect.bottom - triggerRect.top + 8}px`,
                    left: `${triggerRect.left - sliderRect.left}px`, // Corrected
                },
                [MenuPosition.TopRight]: {
                    bottom: `${sliderRect.bottom - triggerRect.top + 8}px`,
                    left: `${triggerRect.right - sliderRect.left - (modalRect?.width || 0)}px`,
                }
            };
            return {
                ...positions[position],
                position: 'absolute' as const
            };
        } else {
            // Regular positioning
            const positions = {
                [MenuPosition.BottomLeft]: {
                    top: `${triggerRect.bottom + 8}px`,
                    right: `${window.innerWidth - triggerRect.right}px`,
                },
                [MenuPosition.BottomRight]: {
                    top: `${triggerRect.bottom + 8}px`,
                    left: `${triggerRect.right - (modalRect?.width || 0)}px`,
                },
                [MenuPosition.TopLeft]: {
                    top: `${triggerRect.top - (modalRect?.height || 0) - 8}px`,
                    left: `${triggerRect.left}px`, // Corrected
                },
                [MenuPosition.TopRight]: {
                    top: `${triggerRect.top - (modalRect?.height || 0) - 8}px`,
                    left: `${triggerRect.right - (modalRect?.width || 0)}px`,
                }
            };
            return {
                ...positions[position],
                position: 'fixed' as const
            };
        }
    };

    if (!isOpen) return null;

    return createPortal(
        <>
            <div
                className={styles.backdrop}
                onClick={onClose}
            />
            <div
                ref={modalRef}
                className={styles.modal}
                style={positionDropdownMenu()}
            >
                {children}
            </div>
        </>,
        document.body
    );
};

export default DropdownMenu;