import { FC, useEffect, useState } from 'react';
import { useLearning } from '../../../../hook/useLearning';
import { LearningArray } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import Modal from '../../../ui/Modal/Modal';
import styles from './SkipModal.module.scss';
interface SkipModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    word: Word;
    handleLearn: () => void;
    index: number;
    setIndex: (index: number) => void;
}

const SkipModal: FC<SkipModalProps> = ({ isModalOpen, setIsModalOpen, word, handleLearn, index, setIndex }) => {
    const [selectedReason, setSelectedReason] = useState('learn');
    const { removeWord, addWord } = useLearning();

    const handleContinue = () => {
        setIsModalOpen(false);
        if (selectedReason === 'learn') {
            handleLearn();
        } else if (selectedReason === 'later') {
            setIndex(index + 1);
        } else if (selectedReason === 'knew') {
            removeWord(LearningArray.ToLearn, word.ID);
            removeWord(LearningArray.UnknownWordIds, word.ID);
            addWord(LearningArray.KnownWordIds, word.ID);
        }
    };


    useEffect(() => {
        setSelectedReason('learn');
    }, [isModalOpen]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
        >
            <div className={styles.skipModal}>
                <h1>{word.Text}</h1>
                <p>'{word.Text}' is the top word in your 'should-learn' list.</p>
                <div className={styles.radioGroup}>
                    <label>
                        <input
                            type="radio"
                            name="skipReason"
                            value="learn"
                            checked={selectedReason === 'learn'}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            className={styles.radioInput}
                        />
                        Learn now (recommended)
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="skipReason"
                            value="later"
                            checked={selectedReason === 'later'}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            className={styles.radioInput}
                        />
                        Learn Later
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="skipReason"
                            value="knew"
                            checked={selectedReason === 'knew'}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            className={styles.radioInput}
                        />
                        Add to 'Already Knew'
                    </label>
                </div>
                <button onClick={handleContinue}>Continue</button>
            </div>
        </Modal>
    );
};

export default SkipModal;