import { useState } from 'react';
import { useComponentModal } from 'src/hook/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { QuestionDone } from 'src/model/Scenario';
import SkipModal from '../SkipModal/SkipModal';
import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
    questionDone: QuestionDone;
    translateText: string | null;
    setShowLottie: (value: boolean) => void;
}

const ActionButtons = ({ questionDone, translateText, setShowLottie }: ActionButtonsProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { open } = useComponentModal();


    const handleStart = (showLottie: boolean = false) => {
        open('LessonWizard', {
            question: questionDone.question,
            translateText: translateText
        }, AnimationType.SlideRight)
        setShowLottie(showLottie);
    };

    const handleSkip = () => {
        setShowLottie(false);
        setIsModalOpen(true);
    };



    if (questionDone.isDone) return (
        <div className={styles.done}>
            <span className={styles.tick}>✓</span>
            <button className={styles.repeat} onClick={() => handleStart(false)}>
                Repeat
            </button>
        </div>);

    return (
        <div className={styles.actionButtons}>
            <button className={styles.skip} onClick={() => handleSkip()}>
                Skip
            </button>
            <button className={styles.next} onClick={() => handleStart(true)}>
                Start
            </button>
            <SkipModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                question={questionDone.question}
            />
        </div>
    );
};

export default ActionButtons;