import { useComponentModal } from 'src/hook/useComponentModal';
import { AnimationType } from 'src/model/enum/enums';
import { Word } from 'src/model/Word';
import { SenseImageLocation } from 'src/utils/URLUtils';
import { ButtonSection } from './ButtonSection/ButtonSection';
import styles from './WordCard.module.scss';

interface WordCardProps {
    word: Word;
}

export const WordCard = ({ word }: WordCardProps) => {
    const { open } = useComponentModal();


    const openDefinition = () => {
        open('Definition', { word }, AnimationType.SlideUp);
    };

    return (
        <div className={styles.wordCard} onDoubleClick={openDefinition}>
            <img
                className={styles.coverImage}
                src={SenseImageLocation(word.PrimarySenseId)}
                alt={word.Text}
            />
            <div className={styles.content}>
                <h3 className={styles.word}>{word.Text}</h3>
                <p className={styles.definition}>
                    {word.PrimaryMeaning.length > 56
                        ? `${word.PrimaryMeaning.slice(0, 56)}...`
                        : word.PrimaryMeaning}
                </p>
                <ButtonSection word={word} />
            </div>
        </div>
    );
}; 