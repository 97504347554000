import { FC, useEffect } from 'react';
import { useToast } from '../../../hook/useToasts';
import styles from './Toast.module.scss';

interface ToastProps {
    id: number;
    message: string;
    type: 'success' | 'error' | 'info';
    duration?: number;
}

const Toast: FC<ToastProps> = ({ id, message, type, duration = 3000 }) => {
    const { removeToastById } = useToast();

    useEffect(() => {
        const timer = setTimeout(() => {
            removeToastById(id);
        }, duration);

        return () => clearTimeout(timer);
    }, [id, duration, removeToastById]);

    return (
        <div className={`${styles.toast} ${styles[type]}`}>
            {message}
        </div>
    );
};

export default Toast;