import React, { useState } from 'react';
import styles from './CustomListForm.module.scss';
import { useNavigate } from 'react-router-dom';


export const CustomListForm = () => {
    const [listName, setListName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();   
        navigate('/wordlists/add-words', { state: { listName } });
    };

    return (
        <form onSubmit={handleSubmit} className={styles.customListForm}>
            <div className={styles.inputContainer}>
                <label htmlFor="listName" className={styles.label}>
                    List Name
                </label>
                <input
                    id="listName"
                    type="text"
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    className={styles.input}
                    placeholder="Enter list name"
                    required
                />
            </div>
            <button
                type="submit"
                className={styles.continueButton}
                disabled={!listName.trim()}
            >
                Continue
            </button>
        </form>
    );
};
