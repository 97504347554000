import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../model/enum/enums';
import { Word } from '../../model/Word';
import { GetResourcesUrl } from '../../utils/URLUtils';
import { parseWordsFile } from '../../utils/WordParser';

interface WordsState {
    words: Word[];
    status: LoadingStatus;
    error: string | null;
}

const initialState: WordsState = {
    words: [],
    status: LoadingStatus.IDLE,
    error: null
};

let isFetching = false;  // Add fetching flag

export const fetchWords = createAsyncThunk(
    'words/fetch',
    async ({ forceRefresh = false }: { forceRefresh?: boolean } = {}, { getState }) => {
        if (isFetching) {
            return (getState() as { words: WordsState }).words.words;
        }

        const state = getState() as { words: WordsState };

        if (
            state.words.status === LoadingStatus.SUCCEEDED &&
            state.words.words.length > 0) {
            return state.words.words;
        }

        try {
            isFetching = true;  // Set fetching flag
            const url = GetResourcesUrl('Words.txt');
            const fetchOptions: RequestInit = {
                cache: forceRefresh ? 'reload' : 'default'
            };

            const response = await fetch(url, fetchOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch words file');
            }

            const text = await response.text();
            return parseWordsFile(text);
        } finally {
            isFetching = false;  // Reset fetching flag
        }
    }
);
const wordsSlice = createSlice({
    name: 'words',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWords.pending, (state) => {
                state.status = LoadingStatus.LOADING;
            })
            .addCase(fetchWords.fulfilled, (state, action) => {
                state.status = LoadingStatus.SUCCEEDED;
                state.words = action.payload;
                state.error = null;
            })
            .addCase(fetchWords.rejected, (state, action) => {
                state.status = LoadingStatus.FAILED;
                state.error = action.error.message ?? 'An unknown error occurred';
            });
    },
});

export default wordsSlice.reducer;