import { FC } from 'react';
import { useComponentModal } from 'src/hook/useComponentModal';
import { useDiscover } from 'src/hook/useDiscover';
import { useLearning } from '../../../../../hook/useLearning';
import { useLearnWizard } from '../../../../../hook/useLearnWizard';
import { AnimationType, LearningArray } from '../../../../../model/enum/enums';
import { Word } from '../../../../../model/Word';
import { WordData } from '../../../../../model/WordData';
import styles from './ButtonSection.module.scss';

interface ButtonSectionProps {
    word: Word;
    wordData: WordData;
}

const ButtonSection: FC<ButtonSectionProps> = ({ word, wordData }) => {
    const { close } = useLearnWizard();
    const { removeWord, addWord } = useLearning();
    const { open } = useComponentModal();
    const { moveNext } = useDiscover();

    const handleLearnMore = () => {
        open('Definition', { word: word }, AnimationType.SlideUp);
    };

    const handleNextWord = () => {
        removeWord(LearningArray.ToLearn, word.ID);
        addWord(LearningArray.UnknownWordIds, word.ID);
        close();
        moveNext?.();
    };


    return (
        <>
            <button className={styles.learnMoreButton} onClick={() => handleLearnMore()}>Learn More</button>
            <button className={styles.nextWordButton} onClick={() => handleNextWord()}>Next Word</button>
        </>
    );
};

export default ButtonSection;