import { useCallback } from "react";
import { useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { Learning } from "src/model/Learning";
import { GetRazorSiteUrl } from "src/utils/URLUtils";
import { WordList } from "../model/WordList";
import { learningActions } from '../store/slices/learningSlice';
import { appDispatch, RootState } from '../store/store';
import useFetch from "./useFetch";

export const useWordList = () => {
    const { send } = useFetch<any>();
    const ac = bindActionCreators(learningActions, appDispatch);
    const { learningInfo } = useSelector((state: RootState) => state.learning);

    const createNewWordList = useCallback((listName: string) => {
        if (!learningInfo) return;

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString()
            .replace(/[:.]/g, '-')
            .slice(0, 19); // Format: "2024-07-25 15-00-05"

        const newWordList: WordList = {
            ID: formattedDate,
            Type: "custom",
            ImageUrl: "",
            Title: listName,
            TotalCount: 1,
            TotalCountText: "1 word",
            KnownCount: 0,
            UnknownCount: 1,
            UncoveredCountText: "",
            IsAll: false,
            IsCompleted: true,
            IsRestricted: false,
            WordsUrl: ``,
            WordListData: []
        };

        const updatedWordLists = [...(learningInfo.WordLists || []), newWordList];
        ac.updateLearning({ WordLists: updatedWordLists });

        return newWordList;
    }, [learningInfo, ac]);

    const fetchWordListData = useCallback(async (wordList: WordList, updateStore: boolean = true) => {
        if (!wordList?.WordsUrl) return null;

        const { response } = await send(wordList.WordsUrl);
        const numberArray = response?.uw
            ?.split(',')
            ?.map((id: string) => parseInt(id.trim(), 10))
            ?.filter((id: number) => !isNaN(id)) ?? [];

        if (learningInfo && updateStore) {
            const updatedWordLists = learningInfo.WordLists.map(wl =>
                wl.ID === wordList.ID ? { ...wl, WordListData: numberArray } : wl
            );
            ac.updateLearning({ WordLists: updatedWordLists });
        }

        return { id: wordList.ID, data: numberArray };
    }, [learningInfo, send, ac]);

    const fetchAllWordListData = useCallback(async () => {
        if (!learningInfo?.WordLists) return;

        const promises = learningInfo.WordLists
            .filter(wordList => wordList.ID !== '-1') // Exclude total vocabulary list
            .map(wordList => fetchWordListData(wordList, false));

        const results = await Promise.all(promises);

        // Update store once with all changes
        const updatedWordLists = learningInfo.WordLists.map(wl => {
            const result = results.find(r => r?.id === wl.ID);
            return result ? { ...wl, WordListData: result.data } : wl;
        });

        ac.updateLearning({ WordLists: updatedWordLists });
        console.log('updatedWordLists', updatedWordLists);
    }, [learningInfo, fetchWordListData, ac]);


    const fetchLearningWithWordList = useCallback(async (token: string) => {
        const { response } = await send(GetRazorSiteUrl('learning', token));
        if (!response) return null;
        const learning = response as Learning;
        const promises = learning.WordLists
            .filter(wordList => wordList.ID !== '-1') // Exclude total vocabulary list
            .map(wordList => fetchWordListData(wordList, false));

        const results = await Promise.all(promises);

        // Update store once with all changes
        const updatedWordLists = learning.WordLists.map(wl => {
            const result = results.find(r => r?.id === wl.ID);
            return result ? { ...wl, WordListData: result.data } : wl;
        });

        ac.setLearning({ ...learning, WordLists: updatedWordLists });

    }, [send, ac, fetchWordListData]);


    const updateWordListStats = useCallback((wordList: WordList) => {
        if (!wordList || !wordList.WordListData) return;

        let updatedWordlist: WordList;
        // Handle the special case for ID '-1' (total vocabulary)
        if (wordList.ID === '-1') {
            const known = learningInfo?.KnownWordIds?.length || 0;
            const unknown = learningInfo?.UnknownWordIds?.length || 0;
            const total = 24241; // Total vocabulary count
            const uncovered = known + unknown;
            const isCompleted = uncovered === total;
            const uncoveredCountText = isCompleted ? '' :
                `${Math.round((uncovered / total) * 100)}%`;

            updatedWordlist = {
                ...wordList,
                KnownCount: known,
                UnknownCount: unknown,
                TotalCount: total,
                UncoveredCountText: uncoveredCountText,
                IsCompleted: isCompleted
            };
        } else {
            // Handle regular wordlist
            const total = wordList.WordListData.length;
            const known = learningInfo?.KnownWordIds?.filter(x => wordList.WordListData.includes(x)).length || 0;
            const unknown = learningInfo?.UnknownWordIds?.filter(x => wordList.WordListData.includes(x)).length || 0;
            const uncovered = known + unknown;
            const isCompleted = uncovered === total;
            const uncoveredCountText = isCompleted ? '' :
                `${Math.round((uncovered / total) * 100)}%`;

            updatedWordlist = {
                ...wordList,
                KnownCount: known,
                UnknownCount: unknown,
                TotalCount: total,
                UncoveredCountText: uncoveredCountText,
                IsCompleted: isCompleted
            };
        }

        const updatedWordLists = learningInfo?.WordLists.map(wl =>
            wl.ID === wordList.ID ? updatedWordlist : wl
        );
        ac.updateLearning({ WordLists: updatedWordLists });
    }, [learningInfo, ac]);

    const removeWordList = useCallback((listName: string) => {
        if (!learningInfo?.WordLists) return;

        const updatedWordLists = learningInfo.WordLists.filter(
            list => list.Title !== listName && list.ID !== '-1' // Prevent removing total vocabulary list
        );

        ac.updateLearning({ WordLists: updatedWordLists });
    }, [learningInfo, ac]);

    const addWordsToList = useCallback((listName: string, wordIds: number[]) => {
        if (!learningInfo?.WordLists) return;

        const updatedWordLists = learningInfo.WordLists.map(list => {
            if (list.Title === listName) {
                const existingIds = list.WordListData || [];
                const uniqueIds = Array.from(new Set([...existingIds, ...wordIds])); // Convert Set to Array explicitly

                return {
                    ...list,
                    WordListData: uniqueIds,
                    TotalCount: uniqueIds.length,
                    TotalCountText: `${uniqueIds.length} ${uniqueIds.length === 1 ? 'word' : 'words'}`
                };
            }
            return list;
        });

        ac.updateLearning({ WordLists: updatedWordLists });
    }, [learningInfo, ac]);

    return {
        updateWordListStats,
        fetchWordListData,
        fetchAllWordListData,
        createNewWordList,
        removeWordList,
        addWordsToList,
        fetchLearningWithWordList
    };
};