import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import pako from 'pako';
import { LoadingStatus } from '../../model/enum/enums';
import { WordTranslate } from '../../model/Word';
import { GetTranslateFilesUrl } from '../../utils/URLUtils';

interface WordTranslateState {
    translates: WordTranslate;
    status: LoadingStatus;
    error: string | null;
}

const initialState: WordTranslateState = {
    translates: {} as WordTranslate,
    status: LoadingStatus.IDLE,
    error: null
};

let isFetchingTranslates = false;  // Add fetching flag

export const fetchTranslates = createAsyncThunk(
    'translates/fetch',
    async ({ languageCode, forceRefresh = false }: { languageCode: string, forceRefresh?: boolean }, { getState }) => {
        if (isFetchingTranslates) {
            return (getState() as { translates: WordTranslateState }).translates.translates;
        }

        const state = getState() as { translates: WordTranslateState };

        if (
            state.translates.status === LoadingStatus.SUCCEEDED &&
            Object.keys(state.translates.translates).length > 0) {
            return state.translates.translates;
        }

        try {
            isFetchingTranslates = true;  // Set fetching flag
            const url = GetTranslateFilesUrl(languageCode);
            const fetchOptions: RequestInit = {
                cache: forceRefresh ? 'reload' : 'default'
            };

            const response = await fetch(url, fetchOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch translations file');
            }

            const compressed = await response.arrayBuffer();
            const decompressed = pako.inflate(new Uint8Array(compressed), { to: 'string' });
            try {
                // First try direct parsing
                return JSON.parse(decompressed) as WordTranslate;
            } catch (error) {
                // If direct parsing fails, try with thorough cleaning
                const cleanedText = decompressed
                    .replaceAll(/[\x00-\x1F\x7F]/g, '') // Remove control characters
                    .replaceAll(/\\/g, '\\\\') // First double all backslashes
                    .replaceAll(/\\\\"/g, '\\"') // Fix double-escaped quotes
                    .replaceAll(/\\\\\\"/g, '\\"') // Fix triple-escaped quotes
                    .replaceAll(/[^\x20-\x7E\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF\s{}[\]:"',.-]/g, '') // Keep only valid characters, Arabic/Persian ranges, and JSON syntax
                    .trim();

                try {
                    return JSON.parse(cleanedText) as WordTranslate;
                } catch (parseError) {
                    // If still failing, log detailed information
                    console.error('Failed to parse JSON after cleaning:', parseError);
                    console.log('Error position:', (parseError as SyntaxError).message);
                    console.log('Characters around error:',
                        cleanedText.substring(4672406, 4672426)); // Show 10 chars before and after error position
                    throw parseError;
                }
            }
        } finally {
            isFetchingTranslates = false;  // Reset fetching flag
        }
    }
);


const translatesSlice = createSlice({
    name: 'translates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTranslates.pending, (state) => {
                state.status = LoadingStatus.LOADING;
            })
            .addCase(fetchTranslates.fulfilled, (state, action) => {
                state.status = LoadingStatus.SUCCEEDED;
                state.translates = action.payload;
                state.error = null;
            })
            .addCase(fetchTranslates.rejected, (state, action) => {
                state.status = LoadingStatus.FAILED;
                state.error = action.error.message ?? 'An unknown error occurred';
            });
    },
});

export default translatesSlice.reducer;